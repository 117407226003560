import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompass } from '@fortawesome/free-solid-svg-icons'; // Import compass icon for longitude

const LatitudeIcon = ({ fillColor }) => {
  return (
    <div style={{ color: fillColor }}>
      <FontAwesomeIcon icon={faCompass} size="1x" />
    </div>
  );
};

export default LatitudeIcon;
