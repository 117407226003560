import React from 'react';
import PropTypes from 'prop-types';

export default class Email extends React.Component {

    static propTypes = {
        fillColor: PropTypes.string.isRequired,
    }

    render() {
        return (
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                <g id="ic_email" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="edit-(3)">
                        <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                    </g>
                    <g id="email-(1)" transform="translate(4.000000, 6.000000)" fill={this.props.fillColor} fillRule="nonzero">
                        <path d="M15.5058594,0 L1.49414062,0 C0.671300781,0 0,0.621415385 0,1.38461538 L0,10.6153846 C0,11.376 0.6683125,12 1.49414062,12 L15.5058594,12 C16.3266406,12 17,11.3806769 17,10.6153846 L17,1.38461538 C17,0.624 16.3316875,0 15.5058594,0 Z M15.2995352,0.923076923 L8.53167578,7.19486154 L1.7052793,0.923076923 L15.2995352,0.923076923 Z M0.99609375,10.4242462 L0.99609375,1.57135385 L5.79324805,5.97873846 L0.99609375,10.4242462 Z M1.70043164,11.0769231 L6.50060742,6.62861538 L8.18257813,8.17390769 C8.37728125,8.3528 8.69148242,8.35221538 8.88538867,8.17249231 L10.5253906,6.65270769 L15.2995684,11.0769231 L1.70043164,11.0769231 Z M16.0039063,10.4242154 L11.2297285,6 L16.0039063,1.57575385 L16.0039063,10.4242154 Z" id="Shape"></path>
                    </g>
                </g>
            </svg>
        );
    }

}
