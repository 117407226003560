import React from 'react';
import {
    ModalHeader,
    ModalBody,
    Button,
    Row,
    Col,
} from 'reactstrap';
import DisplayInputs from '../../../Common/DisplayInputs/DisplayInputs';
// css
import './AddZoneModal.scss';
// assets

import { ZONE_METADATA } from '../../../../Constants/AppConstants';
import { withNamespaces } from 'react-i18next';

class AddZoneModal extends React.Component {

    constructor(props) {
        super(props);

        this.list = JSON.parse(JSON.stringify(ZONE_METADATA));
        this.list.forEach(element => {
            element.md = 6;
            element.invalid = false;
            element.errorMessageZoneZone = '';
        });

        this.state = {
            defaultList: this.list.map(() => ({ checkEmpty: false })),
        };
    }

    validateLongitude = (value) => {
        const longitude = parseFloat(value);
        return !isNaN(longitude) && longitude >= -180 && longitude <= 180;
    };

    validateLatitude = (value) => {
        const latitude = parseFloat(value);
        return !isNaN(latitude) && latitude >= -90 && latitude <= 90;
    };

    validateRange = (value) => {
        const range = parseFloat(value);
        return !isNaN(range) && range > 0;
    };

    // Handle input changes and update values in the list
    handleInputChange = (index, value) => {
        const list = [...this.list];
        list[index].value = value;
        this.setState({ list });
    };

    // Handle save button click
    onSaveClick = () => {
        const { defaultList } = this.state;
        let isInvalid = false;
    
        // Validate each field in the list
        this.list.forEach((element, index) => {
            defaultList[index].checkEmpty = true;
            if (element.isEditable) {
                
                if (element.key === 'longitude' && !this.validateLongitude(element.value)) {
                    element.invalid = true;
                    element.errorMessageZone = 'IinlineInputErrors.longError';
                    isInvalid = true;
                }
              
                else if (element.key === 'latitude' && !this.validateLatitude(element.value)) {
                    element.invalid = true;
                    element.errorMessageZone = 'inlineInputErrors.latiError';
                    isInvalid = true;
                }
                
                else if (element.key === 'range' && !this.validateRange(element.value)) {
                    element.invalid = true;
                    element.errorMessageZone = 'inlineInputErrors.rangeError';
                    isInvalid = true;
                } else {
                    element.invalid = false;
                    element.errorMessageZone = '';
                }
            }
    
        });
    
        
        if (isInvalid) {
            this.setState({ defaultList });
        } else {
           
            this.props.onSave(this.list);  
        }
    };
    render() {
        return (
            <div className="add-zone-modal">
                <ModalHeader>
                    {this.props.i18n.t('modal.addzone.addZone')}
                </ModalHeader>
                <ModalBody>
                    <div className="tile">
                        <DisplayInputs
                            list={this.list}
                            defaultList={this.state.defaultList}
                            isAddNew={true}
                        />
                    </div>

                    <div className="container py-2">
                        <Row>
                            <Col className="d-flex">
                                <Button
                                    className="margin-inline-start-auto"
                                    outline
                                    onClick={this.props.onCancel}
                                >
                                    {this.props.i18n.t('modal.buttons.cancel')}
                                </Button>
                                <Button
                                    className="mx-3"
                                    onClick={this.onSaveClick}
                                >
                                    {this.props.i18n.t('modal.buttons.save')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </div>
        );
    }
}

export default withNamespaces()(AddZoneModal);
