import React from 'react';
import PropTypes from 'prop-types';
import Settings from './Settings';
import { Spinner } from 'reactstrap';
//hoc
import { withModal } from '../../HOC/Modal';
import { axiosInstance } from '../../HOC/Communication';
// constants
import { URLS } from '../../HOC/Communication/ApiUrls';
import { REGEX, PARSING_KEYS, SETTINGS_CONFIG_LIST, SETTINGS_EVENT_CONFIG_LIST,SETTINGS_FEEDBACK_CONFIG_LIST, DATA_UPDATE_LIST, REPORT_LOST_ITEM, SECURITY_SERVICES, ATTENDANCE_SETTINGS ,} from '../../Constants/AppConstants';


/**
 * Settings container 
 */

class SettingsContainer extends React.Component {

    constructor(props) {
        super(props);

        this.init();
        this.state = {
            otherSettings: SETTINGS_CONFIG_LIST,
            feedbacksettings: SETTINGS_FEEDBACK_CONFIG_LIST,
            eventsettings: SETTINGS_EVENT_CONFIG_LIST,
            dataupdate: DATA_UPDATE_LIST,
            reportlostitem: REPORT_LOST_ITEM,
            securityServices:SECURITY_SERVICES,
            attendanceSettings:ATTENDANCE_SETTINGS
        }
    }

    static propTypes = {
        showModal: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
    }

    /**
     * init axios and metadata
     */
    init = () => {
        this.metadata = [
            {
                key: 'linkName',
                label: 'a',
                md: '12',
                isEditable: false,
                className: 'link-name',
            },
            {
                key: 'linkUrl',
                label: 'url',
                md: '12',
                maxCharLimit: 200,
                isEditable: true,
                className: 'link-url',
                regex: REGEX.LINK,
                checkEmpty: true,
                placeholder: 'placeholder.link',
                tooltip: true,
            },
        ]
    }

    /**
     * get settings data after mount
     */
    componentDidMount() {
        this.axios = axiosInstance;
        this.getAllData();
        this.props.handelBackButton();
    }

    /**
     * get required data (common links and other settings data)
     */
    getAllData = () => {
        this.responseFlag = false;
        this.getOtherSettings();
        this.getfeedbackSettings();
        this.geteventSettings();
        this.getdataUpdate();
        this.getreportlostItem();
        this.getSecurityServices();
        this.getAttendancesettings();
    }

    /**
     * get other settings data
     */
    getOtherSettings = () => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.get(URLS.SETTINGS)
            .then(res => {
                if (this.responseFlag)
                    this.props.closeModal();
                this.responseFlag = true;
                if (!res.isError) {
                    const { otherSettings } = this.state;
                    const { SETTINGS } = PARSING_KEYS;
                    const { data } = res.data;
                    otherSettings.forEach(listElement => {
                        const resElement = data.find(dataElement => dataElement[SETTINGS.ID] === listElement[SETTINGS.ID])
                        if (resElement) {
                            listElement[SETTINGS.VALUE] = resElement[SETTINGS.VALUE];
                        }
                    })
                    this.setState({ otherSettings });
                }
                else {
                    this.setState({
                        otherSettings: [],
                    });
                }
            })
    }

    /**
     * get feedback settings data
     */
    getfeedbackSettings = () => {
            this.props.showModal(
                <Spinner />
            );
            this.axios.get(URLS.SETTINGS)
                .then(res => {
                    if (this.responseFlag)
                        this.props.closeModal();
                    this.responseFlag = true;
                    if (!res.isError) {
                        const { feedbacksettings } = this.state;
                        const { SETTINGS } = PARSING_KEYS;
                        const { data } = res.data;
                        feedbacksettings.forEach(listElement => {
                            const resElement = data.find(dataElement => dataElement[SETTINGS.ID] === listElement[SETTINGS.ID])
                            if (resElement) {
                                listElement[SETTINGS.VALUE] = resElement[SETTINGS.VALUE];
                            }
                        })
                        this.setState({ feedbacksettings });
                    }
                    else {
                        this.setState({
                            feedbacksettings: [],
                        });
                    }
                })
        }

    /**
     * get events settings data
     */
    geteventSettings = () => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.get(URLS.SETTINGS)
            .then(res => {
                if (this.responseFlag)
                    this.props.closeModal();
                this.responseFlag = true;
                if (!res.isError) {
                    const { eventsettings } = this.state;
                    const { SETTINGS } = PARSING_KEYS;
                    const { data } = res.data;
                    eventsettings.forEach(listElement => {
                        const resElement = data.find(dataElement => dataElement[SETTINGS.ID] === listElement[SETTINGS.ID])
                        if (resElement) {
                            listElement[SETTINGS.VALUE] = resElement[SETTINGS.VALUE];
                        }
                    })
                    this.setState({ eventsettings });
                }
                else {
                    this.setState({
                        eventsettings: [],
                    });
                }
            })
    }

    /**
     * get Data update settings data
     */
    getdataUpdate = () => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.get(URLS.SETTINGS)
            .then(res => {
                if (this.responseFlag)
                    this.props.closeModal();
                this.responseFlag = true;
                if (!res.isError) {
                    const { dataupdate } = this.state;
                    const { SETTINGS } = PARSING_KEYS;
                    const { data } = res.data;
                    dataupdate.forEach(listElement => {
                        const resElement = data.find(dataElement => dataElement[SETTINGS.ID] === listElement[SETTINGS.ID])
                        if (resElement) {
                            listElement[SETTINGS.VALUE] = resElement[SETTINGS.VALUE];
                        }
                    })
                    this.setState({ dataupdate });
                }
                else {
                    this.setState({
                        dataupdate: [],
                    });
                }
            })
    }

    /**
     * get Report Lost Item settings data
     */
    getreportlostItem = () => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.get(URLS.SETTINGS)
            .then(res => {
                if (this.responseFlag)
                    this.props.closeModal();
                this.responseFlag = true;
                if (!res.isError) {
                    const { reportlostitem } = this.state;
                    const { SETTINGS } = PARSING_KEYS;
                    const { data } = res.data;
                    reportlostitem.forEach(listElement => {
                        const resElement = data.find(dataElement => dataElement[SETTINGS.ID] === listElement[SETTINGS.ID])
                        if (resElement) {
                            listElement[SETTINGS.VALUE] = resElement[SETTINGS.VALUE];
                        }
                    })
                    this.setState({ reportlostitem });
                }
                else {
                    this.setState({
                        reportlostitem: [],
                    });
                }
            })
    }

    getSecurityServices = () => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.get(URLS.SETTINGS)
            .then(res => {
                if (this.responseFlag)
                    this.props.closeModal();
                this.responseFlag = true;
                if (!res.isError) {
                    const { securityServices } = this.state;
                    const { SETTINGS } = PARSING_KEYS;
                    const { data } = res.data;
                    securityServices.forEach(listElement => {
                        const resElement = data.find(dataElement => dataElement[SETTINGS.ID] === listElement[SETTINGS.ID])
                        if (resElement) {
                            listElement[SETTINGS.VALUE] = resElement[SETTINGS.VALUE];
                        }
                    })
                    this.setState({ securityServices});
                }
                else {
                    this.setState({
                        securityServices: [],
                    });
                }
            })
    }


    getAttendancesettings = () => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.get(URLS.SETTINGS)
            .then(res => {
                if (this.responseFlag)
                    this.props.closeModal();
                this.responseFlag = true;
                if (!res.isError) {
                    const { attendanceSettings } = this.state;
                    const { SETTINGS } = PARSING_KEYS;
                    const { data } = res.data;
                    attendanceSettings.forEach(listElement => {
                        const resElement = data.find(dataElement => dataElement[SETTINGS.ID] === listElement[SETTINGS.ID])
                        if (resElement) {
                            listElement[SETTINGS.VALUE] = resElement[SETTINGS.VALUE];
                        }
                    })
                    this.setState({ attendanceSettings});
                }
                else {
                    this.setState({
                        attendanceSetting: [],
                    });
                }
            })
    }

    /**
     * get other settngs by code
     */
    getOtherSettingById = (id, callback) => {
        this.props.showModal(
            <Spinner />
        );

        const url = URLS.SETTINGS + URLS.CONFIDS + id
        this.axios.get(url)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    const { SETTINGS } = PARSING_KEYS;
                    const { otherSettings } = this.state;
                    const index = otherSettings.findIndex(element => element[SETTINGS.ID] === id)
                    otherSettings[index][SETTINGS.VALUE] = res.data.data.find(element => element[SETTINGS.ID] === id)[SETTINGS.VALUE];
                    this.setState({ otherSettings })
                    callback(otherSettings);
                }
            })
    }


    /**
     * get feedback settngs by code
    */
    getFeedbackSettingById = (id, callback) => {
        this.props.showModal(
            <Spinner />
        );
    
        const url = URLS.SETTINGS + URLS.CONFIDS + id
        this.axios.get(url)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    const { SETTINGS } = PARSING_KEYS;
                    const { feedbacksettings } = this.state;
                    const index = feedbacksettings.findIndex(element => element[SETTINGS.ID] === id)
                    feedbacksettings[index][SETTINGS.VALUE] = res.data.data.find(element => element[SETTINGS.ID] === id)[SETTINGS.VALUE];
                    this.setState({ feedbacksettings })
                    callback(feedbacksettings);
                }
            })
    }

    /**
     * get event settngs by code
    */
    getEventSettingById = (id, callback) => {
        this.props.showModal(
            <Spinner />
        );
        
        const url = URLS.SETTINGS + URLS.CONFIDS + id
        this.axios.get(url)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    const { SETTINGS } = PARSING_KEYS;
                    const { eventsettings } = this.state;
                    const index = eventsettings.findIndex(element => element[SETTINGS.ID] === id)
                    eventsettings[index][SETTINGS.VALUE] = res.data.data.find(element => element[SETTINGS.ID] === id)[SETTINGS.VALUE];
                    this.setState({ eventsettings })
                    callback(eventsettings);
                }
            })
    }

    /**
     * get Data Update by code
    */
    getDataUpdateById = (id, callback) => {
        this.props.showModal(
            <Spinner />
        );
        
        const url = URLS.SETTINGS + URLS.CONFIDS + id
        this.axios.get(url)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    const { SETTINGS } = PARSING_KEYS;
                    const { dataupdate } = this.state;
                    const index = dataupdate.findIndex(element => element[SETTINGS.ID] === id)
                    dataupdate[index][SETTINGS.VALUE] = res.data.data.find(element => element[SETTINGS.ID] === id)[SETTINGS.VALUE];
                    this.setState({ dataupdate })
                    callback(dataupdate);
                }
            })
    }

    /**
     * get Lost Item Report by code
    */
    getReportLostItemById = (id, callback) => {
        this.props.showModal(
            <Spinner />
        );
        
        const url = URLS.SETTINGS + URLS.CONFIDS + id
        this.axios.get(url)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    const { SETTINGS } = PARSING_KEYS;
                    const { reportlostitem } = this.state;
                    const index = reportlostitem.findIndex(element => element[SETTINGS.ID] === id)
                    reportlostitem[index][SETTINGS.VALUE] = res.data.data.find(element => element[SETTINGS.ID] === id)[SETTINGS.VALUE];
                    this.setState({ reportlostitem })
                    callback(reportlostitem);
                }
            })
    }

    getSecuityServicesById = (id, callback) => {
        this.props.showModal(
            <Spinner />
        );
        
        const url = URLS.SETTINGS + URLS.CONFIDS + id
        this.axios.get(url)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    const { SETTINGS } = PARSING_KEYS;
                    const { securityServices } = this.state;
                    const index = securityServices.findIndex(element => element[SETTINGS.ID] === id)
                    securityServices[index][SETTINGS.VALUE] = res.data.data.find(element => element[SETTINGS.ID] === id)[SETTINGS.VALUE];
                    this.setState({ securityServices })
                    callback(securityServices);
                }
            })
    }

    getAttendanceById= (id, callback) => {
        this.props.showModal(
            <Spinner />
        );
        
        const url = URLS.SETTINGS + URLS.CONFIDS + id
        this.axios.get(url)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    const { SETTINGS } = PARSING_KEYS;
                    const { attendanceSettings } = this.state;
                    const index = attendanceSettings.findIndex(element => element[SETTINGS.ID] === id)
                    attendanceSettings[index][SETTINGS.VALUE] = res.data.data.find(element => element[SETTINGS.ID] === id)[SETTINGS.VALUE];
                    this.setState({ attendanceSettings })
                    callback(attendanceSettings);
                }
            })
    }

    /**
     * edit specific link in other settings by id
     */
    putOtherSettingById = (requestBody) => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.put(URLS.SETTINGS,
            [
                requestBody,
            ]
        )
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    this.getOtherSettings();
                }
            })
    }

    /**
     * edit specific link in feedback settings by id
     */
    putFeedbackSettingById = (requestBody) => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.put(URLS.SETTINGS,
            [
                requestBody,
            ]
        )
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    this.getfeedbackSettings();
                }
            })
    }

    /**
     * edit specific link in event settings by id
     */
    putEventSettingById = (requestBody) => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.put(URLS.SETTINGS,
            [
                requestBody,
            ]
        )
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    this.geteventSettings();
                }
            })
    }

    /**
    * edit specific link in Data Update settings by id
    */
    putDataUpdateById = (requestBody) => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.put(URLS.SETTINGS,
            [
                requestBody,
            ]
        )
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    this.getdataUpdate();
                }
            })
    }

    /**
    * edit specific link in Report lost item settings by id
    */
    putReportLostItemById = (requestBody) => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.put(URLS.SETTINGS,
            [
                requestBody,
            ]
        )
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    this.getreportlostItem();
                }
            })
    }

    putSecurityServicesById = (requestBody) => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.put(URLS.SETTINGS,
            [
                requestBody,
            ]
        )
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    this.getSecurityServices();
                }
            })
    }

    putAttendanceSettingsById = (requestBody) => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.put(URLS.SETTINGS,
            [
                requestBody,
            ]
        )
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    this.getAttendancesettings();
                }
            })
    }
 

    render() {
        const { SETTINGS } = PARSING_KEYS;
        const { otherSettings } = this.state;
        const { feedbacksettings } = this.state;
        const { eventsettings } = this.state;
        const { dataupdate } = this.state;
        const { reportlostitem } = this.state;
        const { securityServices } = this.state;
        const { attendanceSettings } = this.state;

        const filteredSettings = [];
        const filteredFeedbackSettings = [];
        const filteredEventSettings = [];
        const filteredDataUpdate=[];
        const filteredReportLostItems=[];
        const filteredSecurityServices=[]
        const filteredAttendanceSetting=[]

        otherSettings.forEach(element => {
            if (typeof element[SETTINGS.VALUE] !== 'undefined') {
                filteredSettings.push(element);
            }
        })
        feedbacksettings.forEach(element => {
            if (typeof element[SETTINGS.VALUE] !== 'undefined') {
                filteredFeedbackSettings.push(element);
            }
        })
        eventsettings.forEach(element => {
            if (typeof element[SETTINGS.VALUE] !== 'undefined') {
                filteredEventSettings.push(element);
            }
        })  
        dataupdate.forEach(element=> {
            if (typeof element[SETTINGS.VALUE] !== 'undefined') {
                filteredDataUpdate.push(element);
            }
        }) 
        reportlostitem.forEach(element => {
            if (typeof element[SETTINGS.VALUE] !== 'undefined') {
                filteredReportLostItems.push(element);
            }
        })
        securityServices.forEach(element => {
            if (typeof element[SETTINGS.VALUE] !== 'undefined') {
                filteredSecurityServices.push(element);
            }
        })

        attendanceSettings.forEach(element => {
            if (typeof element[SETTINGS.VALUE] !== 'undefined') {
                filteredAttendanceSetting.push(element);
            }
        })
       
        return (
            <Settings
                showModal={this.props.showModal}
                closeModal={this.props.closeModal}
                metadata={this.metadata}
                otherSettings={filteredSettings} // {this.state.otherSettings}
                feedbacksettings={filteredFeedbackSettings}
                eventsettings={filteredEventSettings}
                dataupdate={filteredDataUpdate}
                reportlostitem={filteredReportLostItems}
                securityServices={filteredSecurityServices}
                attendanceSettings={filteredAttendanceSetting}
                getOtherSettings={this.getOtherSettings}
                getfeedbackSettings={this.getfeedbackSettings}
                geteventSettings={this.geteventSettings}
                getdataUpdate={this.getdataUpdate}
                getreportlostItem={this.getreportlostItem}
                getSecurityServices={this.getSecurityServices}
                getAttendancesettings={this.getAttendancesettings}
                getOtherSettingById={this.getOtherSettingById}
                getFeedbackSettingById={this.getFeedbackSettingById}
                getEventSettingById={this.getEventSettingById}
                getDataUpdateById={this.getDataUpdateById}
                getReportLostItemById={this.getReportLostItemById}
                getSecurityServicesById={this.getSecuityServicesById}
                getAttendanceById={this.getAttendanceById}
                putOtherSettingById={this.putOtherSettingById}
                putFeedbackSettingById={this.putFeedbackSettingById}
                putEventSettingById={this.putFeedbackSettingById}
                putDataUpdateById={this.putDataUpdateById}
                putReportLostItemById={this.putReportLostItemById}
                putSecurityServicesById={this.putSecurityServicesById}
                putAttendanceSettingsById={this.putAttendanceSettingsById}

            />
        );
    }
}

export default withModal(SettingsContainer);
