import React from 'react';
import './pagination.scss'; // Import the common pagination styles

const Pagination = ({ currentPage,totalItems, onPageChange }) => {
    const totalPages = totalItems
    const presentPage=currentPage+1
    const maxPageNumbersToShow = 5; // Number of page numbers to display at once

    if (totalPages === 1) return null; // No pagination needed if only one page

    let pages = [];
    let startPage, endPage;

    if (totalPages <= maxPageNumbersToShow) {
        startPage = 1;
        endPage = totalPages;
    } else {
        const maxPagesBeforeCurrentPage = Math.floor(maxPageNumbersToShow / 2);
        const maxPagesAfterCurrentPage = Math.ceil(maxPageNumbersToShow / 2) - 1;
        if (presentPage <= maxPagesBeforeCurrentPage) {
            startPage = 1;
            endPage = maxPageNumbersToShow;
        } else if (presentPage + maxPagesAfterCurrentPage >= totalPages) {
            startPage = totalPages - maxPageNumbersToShow + 1;
            endPage = totalPages;
        } else {
            startPage = presentPage - maxPagesBeforeCurrentPage;
            endPage = presentPage + maxPagesAfterCurrentPage;
        }
    }

    // Add "Previous" button
    pages.push(
        <button
            key="prev"
            className={`page-item ${presentPage === 1 ? 'disabled' : ''}`}
            onClick={() => onPageChange(presentPage - 1)}
            disabled={presentPage === 1}
        >
            Previous
        </button>
    );

    // Add first page and ellipsis if needed
    if (startPage > 1) {
        pages.push(
            <button
                key={1}
                className={`page-item ${presentPage === 1 ? 'active' : ''}`}
                onClick={() => onPageChange(1)}
            >
                1
            </button>
        );
        if (startPage > 2) {
            pages.push(<span key="start-ellipsis" className="ellipsis">...</span>);
        }
    }

    // Add page number buttons
    for (let page = startPage; page <= endPage; page++) {
        pages.push(
            <button
                key={page}
                className={`page-item ${presentPage === page ? 'active' : ''}`}
                onClick={() => onPageChange(page)}
            >
                {page}
            </button>
        );
    }

    // Add last page and ellipsis if needed
    if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
            pages.push(<span key="end-ellipsis" className="ellipsis">...</span>);
        }
        pages.push(
            <button
                key={totalPages}
                className={`page-item ${presentPage === totalPages ? 'active' : ''}`}
                onClick={() => onPageChange(totalPages)}
            >
                {totalPages}
            </button>
        );
    }

    // Add "Next" button
    pages.push(
        <button
            key="next"
            className={`page-item ${presentPage === totalPages ? 'disabled' : ''}`}
            onClick={() => onPageChange(presentPage + 1)}
            disabled={presentPage === totalPages}
        >
            Next
        </button>
    );

    return <div className="pagination">{pages}</div>;
};

export default Pagination;
