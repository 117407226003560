import React from 'react';
import PropTypes from 'prop-types';

export default class Delete extends React.Component {

    static propTypes = {
        fillColor: PropTypes.string.isRequired,
    }

    render() {
        return (
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                <g id="ic_delete" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="delete">
                        <rect id="Rectangle-Copy-2" x="0" y="0" width="24" height="24"></rect>
                        <g id="Group" transform="translate(4.000000, 4.467532)" fill={this.props.fillColor}>
                            <polygon id="Path" points="11.2660753 3.60318976 9.9245523 3.55050002 9.63370711 12.0555562 10.9752301 12.1080309"></polygon>
                            <rect id="Rectangle" x="7.32880335" y="3.57684489" width="1.34239331" height="8.50509204"></rect>
                            <polygon id="Path" points="6.36605858 12.0553412 6.07521339 3.55024912 4.73369038 3.6029747 5.02456904 12.1080309"></polygon>
                            <path d="M0.0126861925,0.0310761944 L0.0126861925,1.46853837 L1.41148117,1.46853837 L2.52117155,14.9719872 C2.55159833,15.3430735 2.84180753,15.6277056 3.18969038,15.6277056 L12.787682,15.6277056 C13.1355983,15.6277056 13.4260084,15.3428584 13.4562008,14.9717363 L14.5659247,1.46853837 L15.9873138,1.46853837 L15.9873138,0.0310761944 L0.0126861925,0.0310761944 Z M12.172887,14.1902435 L3.80431799,14.1902435 L2.75902929,1.46853837 L13.21841,1.46853837 L12.172887,14.1902435 Z" id="Shape"></path>
                        </g>
                        <path d="M14.7837936,2 L10.0162064,2 C9.34558247,2 8.8,2.50892321 8.8,3.13448598 L8.8,5.29004329 L10.2594477,5.29004329 L10.2594477,3.36138318 L14.5405523,3.36138318 L14.5405523,5.29004329 L16,5.29004329 L16,3.13448598 C16,2.50892321 15.4544175,2 14.7837936,2 Z" id="Path" fill={this.props.fillColor}></path>
                    </g>
                </g>
            </svg>
        );
    }

}
