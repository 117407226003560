import React from 'react';
import PropTypes from 'prop-types';

export default class Edit extends React.Component {

    static propTypes = {
        fillColor: PropTypes.string.isRequired,
    }

    render() {
        return (
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                <g id="ic_edit" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="edit-(3)">
                        <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                        <path d="M18.8762277,4.09968298 C17.4149511,2.63478936 15.0385681,2.63478936 13.5772915,4.09968298 L3.86197234,13.8113851 C3.78601489,13.8873426 3.73899362,13.9850021 3.72452553,14.0898957 L3.0047383,19.4213851 C2.98303617,19.5733 3.03729149,19.7252149 3.14218511,19.8301085 C3.23261064,19.920534 3.35920638,19.9747894 3.48580213,19.9747894 C3.50750426,19.9747894 3.52920638,19.9747894 3.55090851,19.9711723 L6.7628234,19.5371298 C7.03048298,19.5009596 7.21856809,19.2550021 7.18239787,18.9873426 C7.14622766,18.719683 6.90027021,18.5315979 6.63261064,18.5677681 L4.05729149,18.9150021 L4.56005745,15.1967043 L8.47367447,19.1103213 C8.5641,19.2007468 8.69069574,19.2550021 8.81729149,19.2550021 C8.94388723,19.2550021 9.07048298,19.2043638 9.16090851,19.1103213 L18.8762277,9.39861915 C19.5851638,8.68968298 19.9758021,7.74925745 19.9758021,6.74734255 C19.9758021,5.74542766 19.5851638,4.80500213 18.8762277,4.09968298 Z M13.7653766,5.2933 L15.3966532,6.9245766 L6.53133404,15.7898957 L4.90005745,14.1586191 L13.7653766,5.2933 Z M8.82090851,18.0758532 L7.22580213,16.4807468 L16.0911213,7.61542766 L17.6862277,9.21053404 L8.82090851,18.0758532 Z M18.3662277,8.51244894 L14.4634617,4.60968298 C14.9589936,4.20095957 15.5775043,3.97670426 16.2285681,3.97670426 C16.9700574,3.97670426 17.6645255,4.26606596 18.1889936,4.78691702 C18.7134617,5.30776809 18.9992064,6.00585319 18.9992064,6.74734255 C18.9992064,7.4020234 18.7749511,8.01691702 18.3662277,8.51244894 Z" id="Shape" fill={this.props.fillColor}></path>
                    </g>
                </g>
            </svg>
        );
    }

}

