import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRuler } from '@fortawesome/free-solid-svg-icons'; // Import ruler icon for range

const RangeIcon = ({ fillColor }) => {
  return (
    <div style={{ color: fillColor }}>
      <FontAwesomeIcon icon={faRuler} size="1x" />
    </div>
  );
};

export default RangeIcon;
