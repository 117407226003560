import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { APPROVAL_HEAD } from '../../../Constants/AppConstants';
import { formatUtcToLocal } from '../../Common/commonutility';
import { withNamespaces } from 'react-i18next';
import './Approval.scss';
import { URLS } from '../../../HOC/Communication/ApiUrls';
import { axiosInstance } from '../../../HOC/Communication';
import { Spinner } from 'reactstrap';
import { withModal } from '../../../HOC/Modal';

class EmployeeDetailsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            remark: this.props.remark || '',
            error: "",
            image: "",
            imgLoad: false
        };

    }

    componentDidMount() {
        this.axios = axiosInstance;
        const { selectedEmployee } = this.props;
        if (selectedEmployee && selectedEmployee.id) {
            this.getImage(selectedEmployee.id,selectedEmployee.empImage);
        }
    }

    getImage = (id,img) => {
        this.setState({ image:img, imgLoad: true });
        this.axios
            .get(URLS.GET_APPROVALS + id + '/image')
            .then((res) => {
                const image = res.data.data.image;
                this.setState({ image, imgLoad: false });
            })
            .catch(() => {
                // Handle error scenario and stop loading spinner
                this.setState({ imgLoad: false });
            });
    }

    handleApproval = (status) => {
        const { remark } = this.state;
        const { selectedEmployee } = this.props;
        this.props.editStatus && this.props.editStatus(selectedEmployee.id, status, remark);
        // if (!remark.trim()) {

        //     this.setState({ error: this.props.i18n.t('errorMessages.remarkRequired') });
        // } else {
        //     this.setState({ error: '' });
        //     this.props.editStatus && this.props.editStatus(selectedEmployee.id, status, remark);
        // }

    };

    handleRemarkChange = (event) => {
        this.setState({ remark: event.target.value });
    };

    render() {
        const { selectedEmpList, selectedEmployee, closeModal, i18n } = this.props;
        const { remark, image, imgLoad } = this.state;

        return (
            <div className="employee-details-modal">

                <div className="employee-modal-content">
                    <div className='modal-header'>
                        <span>{i18n.t("approvals.requestDetails")}</span>
                        <button className="close-btn" onClick={closeModal}>×</button>
                    </div>

                    <div className="viewmodal-body">
                        
                            <div style={{ position: 'relative' }}>
                            {imgLoad ? (
                                <Spinner
                                    className='image-spinner'
                                />):null}

                                <img
                                    src={`data:image/jpeg;base64,${image}`}
                                    alt="Employee"
                                    className="modal-image"
                                />
                            </div> 
                        
                        <div className="viewmodal-details">
                            <div>
                                <p>
                                    {i18n.t('approvals.name')}
                                    <span className="val">{selectedEmpList.fullNameEn}</span>
                                </p>
                                <p>
                                    {i18n.t('approvals.id')}
                                    <span className="val">{selectedEmpList.empId}</span>
                                </p>
                                <p>
                                    {i18n.t('approvals.dept')}
                                    <span className="val">{selectedEmpList.deptEn}</span>
                                </p>
                                <p>
                                    {i18n.t('approvals.designation')}
                                    <span className="val">{selectedEmpList.jobEn}</span>
                                </p>
                                {selectedEmployee.approvalStatus !== APPROVAL_HEAD[0].activeTab && (
                                    <>
                                        <p>
                                            {selectedEmployee.approvalStatus === APPROVAL_HEAD[1].activeTab
                                                ? i18n.t('approvals.updatedby')
                                                : selectedEmployee.approvalStatus === APPROVAL_HEAD[2].activeTab
                                                    ? i18n.t('approvals.rejectedby')
                                                    : ''}
                                            <span className="val"> {selectedEmployee.approverName} ({selectedEmployee.approverId})</span>
                                        </p>
                                        <p>
                                            {selectedEmployee.approvalStatus === APPROVAL_HEAD[1].activeTab
                                                ? i18n.t('approvals.updatedat')
                                                : selectedEmployee.approvalStatus === APPROVAL_HEAD[2].activeTab
                                                    ? i18n.t('approvals.rejectedat')
                                                    : ''}
                                            <span className="val">
                                                {formatUtcToLocal(selectedEmployee.updatedAt, 'MMM dd, yyyy HH:mm:ss')}
                                            </span>
                                        </p>
                                        {selectedEmployee.approvalRemarks &&(
                                        <p>
                                            {i18n.t('approvals.remark')}
                                            <span className="val">{selectedEmployee.approvalRemarks}</span>
                                        </p>
                                        )}
                                    </>
                                )}
                            </div>

                            {(selectedEmployee.approvalStatus === APPROVAL_HEAD[0].activeTab) ||(selectedEmployee.approvalStatus === APPROVAL_HEAD[1].activeTab) ?
                                <div className="remark-formgroup">
                                    <div className="form-group">
                                        <textarea
                                            id="remark"
                                            className="remark-input-box"
                                            value={remark}
                                            onChange={this.handleRemarkChange}
                                            rows="4"
                                            placeholder={i18n.t('approvals.enterRemarkplace')}
                                        />
                                        {/* {error && <span className="error-text">{error}</span>} */}
                                    </div>
                                    <div className="viewmodal-actions">
                                    {selectedEmployee.approvalStatus === APPROVAL_HEAD[0].activeTab &&(
                                        <button
                                            type="button"
                                            className="btn-approval approve-btn"
                                            onClick={() => this.handleApproval('Approved')}
                                        >
                                            {i18n.t('modal.buttons.approve')}
                                        </button>)}
                                        <button
                                            type="button"
                                            className="btn-approval deny-btn"
                                            onClick={() => this.handleApproval('Rejected')}
                                        >
                                            {i18n.t('modal.buttons.reject')}
                                        </button>
                                    </div>
                                </div>
                            :null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

EmployeeDetailsModal.propTypes = {
    selectedEmpList: PropTypes.object.isRequired,
    selectedEmployee: PropTypes.object.isRequired,
    toggleModal: PropTypes.func.isRequired,
    editStatus: PropTypes.func.isRequired,
    remark: PropTypes.string,
    i18n: PropTypes.object.isRequired,
};

export default withNamespaces()(withModal(EmployeeDetailsModal));
