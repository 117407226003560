import React from 'react';
import PropTypes from 'prop-types';
import Login from './Login';
import { Spinner } from 'reactstrap';
// hoc
import { compose } from 'recompose';
import { withModal } from '../../HOC/Modal';
import { withRouter } from 'react-router-dom';
import { axiosInstance } from '../../HOC/Communication';

// constants
import { URLS } from '../../HOC/Communication/ApiUrls';
import { DEFAULT_ROUTE } from '../../Constants/AppConstants';

// storage
import Storage from '../../Storage';

/**
 * Login class Container
 */
class LoginContainer extends React.Component {

    static propTypes = {
        showModal: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);

        const auth = Storage.get(Storage.keys.AUTH);
        const userDisplayName = (auth) ? auth.userDisplayName : '';
        if (userDisplayName) {
            this.props.history.push(DEFAULT_ROUTE);
        }
    }

    /**
     * disable back btn
     */
    handelBackButton = () => {
        window.onpopstate = () => {
            this.props.history.push(this.props.history.location.pathname);
        }
    }

    /**
     * login api
     * params : userId and password
     */
    login = (userId, password) => {
        this.props.showModal(
            <Spinner />
        );
        axiosInstance.post(URLS.LOGIN, {
            userId,
            password,
        })
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    const { userId, roleId, userDisplayName } = res.data.data;
                    // check roll id === 1
                    Storage.set(Storage.keys.AUTH, {
                        userId,
                        roleId,
                        userDisplayName,
                    })
                    this.props.history.push('/');
                }
            })
    }

    /**
     * opens forgot password link in new window
     */
    forgotPassword = () => {
        this.props.showModal(
            <Spinner />
        );
        axiosInstance.get(URLS.FORGOT_PASSWORD)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    window.open(res.data.data.url);
                }
            })
    }

    componentDidMount() {
        this.handelBackButton();
    }

    render() {
        return (
            <Login
                login={this.login}
                forgotPassword={this.forgotPassword}
            />
        );
    }
}

export default compose(
    withModal,
    withRouter,
)(LoginContainer);