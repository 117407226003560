import React from 'react';
import {
    Row,
    Col,
    Button,
} from 'reactstrap';
import CustomList from '../Common/CustomList/CustomList';
import CustomCard from '../Common/CustomCard/CustomCard';
import AddEditModal from '../Common/AddEditModal/AddEditModal';
// hoc
import { withNamespaces } from 'react-i18next';
import { MODAL_SIZE } from '../../HOC/Modal';
// css
import './FacilityLocations.scss'
// constants
import { PARSING_KEYS } from '../../Constants/AppConstants';
// assets
import Buildings from '../../Assets/ic_building.svg';
import Floors from '../../Assets/ic_floors.svg';
import Locations from '../../Assets/ic_location.svg';

class FacilityLocations extends React.Component {

    constructor(props) {
        super(props);

        this.list = [
            {
                key: PARSING_KEYS.COMMON.NAME_EN,
                label: this.props.i18n.t('modal.addCampus.nameEN'),
                md: "12",
                value: "",
                maxCharLimit: 100,
                isEditable: true,
            },
            {
                key: PARSING_KEYS.COMMON.NAME_AR,
                label: this.props.i18n.t('modal.addCampus.nameAR'),
                md: "12",
                value: "",
                maxCharLimit: 100,
                isEditable: true,
            },
        ]
    }

    showModal = (modalHeader, onSaveClick, onCancelClick, specificKeyList) => {
        const list = JSON.parse(JSON.stringify(this.list))
        list.forEach((element, index) => {
            element.placeholder = specificKeyList[index].placeholder;
        })
        this.props.setEditingOn(-100, -100, -100);
        this.props.showModal(
            <AddEditModal
                modalHeader={modalHeader}
                onSaveClick={() => onSaveClick(list)}
                onCancelClick={onCancelClick}
                list={list}
            />
            , MODAL_SIZE.MD
        )
    }

    addBuilding = () => {
        this.showModal(
            "facilityLocations.addBuilding",
            (list) => this.props.addBuilding(list),
            this.props.closeModal,
            this.props.buildingsMetadata,
        );
    }

    addFloor = () => {
        this.showModal(
            "facilityLocations.addFloor",
            this.props.addFloor,
            this.props.closeModal,
            this.props.floorsMetadata,
        );
    }

    addLocation = () => {
        this.showModal(
            "facilityLocations.addLocation",
            this.props.addLocation,
            this.props.closeModal,
            this.props.locationsMetadata,
        );
    }

    render() {
        return (
            <div className="container facility-locations">
                <Row className="campus-name">
                    <Col md={12}>
                        {this.props.campsNameEn}
                    </Col>
                    <Col md={12}>
                        {this.props.campsNameAr}
                    </Col>
                </Row>
                <Row className="cards-container">
                    <Col md={4} className="h-100">
                        <CustomCard
                            header={
                                <div className="card-header-container">
                                    <img
                                        src={Buildings}
                                        alt='buildings'
                                    />
                                    {this.props.i18n.t('facilityLocations.buildings')}
                                    <Button
                                        className="margin-inline-start-auto"
                                        onClick={() => this.addBuilding()}
                                    >
                                        {this.props.i18n.t('modal.buttons.add')}
                                    </Button>
                                </div>
                            }
                            body={
                                <CustomList
                                    list={this.props.buildings}
                                    keys={this.props.buildingsMetadata}
                                    inlineEdit
                                    showModal={this.props.showModal}
                                    closeModal={this.props.closeModal}
                                    editingOn={this.props.editingOnBuildingId}
                                    setEditingOn={(id) => this.props.setEditingOn(id, -100, -100)}
                                    selectedIndex={this.props.buildingId}
                                    setSelectedIndex={this.props.getFloorsLocations}
                                    onEditClick={this.props.getBuildingById}
                                    onEdited={this.props.editBuildingById}
                                    onDelete={this.props.deleteBuildingById}
                                    deleteModalMessage='modal.confirmation.deleteBuildingMessage'
                                />
                            }
                        />
                    </Col>
                    <Col md={4} className="h-100">
                        <CustomCard
                            header={
                                <div className="card-header-container">
                                    <img
                                        src={Floors}
                                        alt='floors'
                                    />
                                    {this.props.i18n.t('facilityLocations.floor')}
                                    <Button
                                        className="margin-inline-start-auto"
                                        onClick={() => this.addFloor()}
                                    >
                                        {this.props.i18n.t('modal.buttons.add')}
                                    </Button>
                                </div>
                            }
                            body={
                                <CustomList
                                    list={this.props.floors}
                                    keys={this.props.floorsMetadata}
                                    inlineEdit
                                    showModal={this.props.showModal}
                                    closeModal={this.props.closeModal}
                                    editingOn={this.props.editingOnFloorId}
                                    setEditingOn={(id) => this.props.setEditingOn(id, -100, -100)}
                                    selectedIndex={this.props.floorId}
                                    setSelectedIndex={(id) => this.props.getLocations(
                                        this.props.buildingId,
                                        id,
                                        1,
                                    )}
                                    onEditClick={this.props.getFloorById}
                                    onEdited={this.props.editFloorById}
                                    onDelete={this.props.deleteFloorById}
                                    deleteModalMessage='modal.confirmation.deleteFloorMessage'
                                />
                            }
                        />
                    </Col>
                    <Col md={4} className="locations-list h-100">
                        <CustomCard
                            header={
                                <div className="card-header-container">
                                    <img
                                        src={Locations}
                                        alt='locations'
                                    />
                                    {this.props.i18n.t('facilityLocations.locations')}
                                    <Button
                                        disabled={(this.props.floors && this.props.floors.length === 0) ? true : false}
                                        className="margin-inline-start-auto"
                                        onClick={() => this.addLocation()}
                                    >
                                        {this.props.i18n.t('modal.buttons.add')}
                                    </Button>
                                </div>
                            }
                            body={
                                <CustomList
                                    list={this.props.locations}
                                    keys={this.props.locationsMetadata}
                                    inlineEdit
                                    showModal={this.props.showModal}
                                    closeModal={this.props.closeModal}
                                    editingOn={this.props.editingOnLocationId}
                                    setEditingOn={(id) => this.props.setEditingOn(-100, -100, id)}
                                    onEditClick={this.props.getLocationById}
                                    onEdited={this.props.editLocationById}
                                    onDelete={this.props.deleteLocationById}
                                    deleteModalMessage='modal.confirmation.deleteLocationMessage'
                                />
                            }
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default withNamespaces()(FacilityLocations);