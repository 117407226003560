import React from 'react';

export default class Disconnected extends React.Component {

    render() {
        return (
            <svg width="150px" height="108px" viewBox="0 0 184 108" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                <g id="Final" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="05_Error" transform="translate(-870.000000, -399.000000)">
                        <g id="Group-13" transform="translate(617.000000, 283.000000)">
                            <g id="Group-14" transform="translate(160.000000, 111.000000)">
                                <g id="ic_connection_failed" transform="translate(95.000000, 4.000000)">
                                    <g id="Group-26-Copy-2" transform="translate(0.000000, 33.464382)">
                                        <path d="M73.646193,5.68434189e-14 L73.6468998,5.63993297e-14 C75.842784,5.59959523e-14 77.6228998,1.78011586 77.6228998,3.97600005 L77.6228998,39.9825211 C77.6228998,42.1784053 75.842784,43.9585212 73.6468998,43.9585212 L73.646193,43.9585212 C71.4503088,43.9585212 69.6701929,42.1784053 69.6701929,39.9825211 L69.6701929,3.97600005 C69.6701929,1.78011586 71.4503088,5.50263502e-14 73.646193,5.46229728e-14 Z" id="Rectangle-Copy-10" fill="#0271A4"></path>
                                        <path d="M78.6309894,10.1442741 L89.4853922,10.1442741 C90.4191468,10.1442741 91.1761045,10.9012318 91.1761045,11.8349865 L91.1761045,11.8349865 C91.1761045,12.7687411 90.4191468,13.5256988 89.4853922,13.5256988 L78.6309894,13.5256988 L78.6309894,10.1442741 Z" id="Rectangle-Copy-11" fill="#0271A4"></path>
                                        <path d="M37.8592259,18.3724076 L43.0118233,18.3724076 L43.0118233,25.5861136 L37.8592259,25.5861136 C36.9932434,25.5861136 36.2912259,24.8840961 36.2912259,24.0181136 L36.2912259,19.9404076 C36.2912259,19.0744251 36.9932434,18.3724076 37.8592259,18.3724076 Z" id="Rectangle-Copy-12" fill="#0271A4"></path>
                                        <path d="M78.6309894,30.5455365 L89.4853922,30.5455365 C90.4191468,30.5455365 91.1761045,31.3024942 91.1761045,32.2362489 L91.1761045,32.2362489 C91.1761045,33.1700035 90.4191468,33.9269612 89.4853922,33.9269612 L78.6309894,33.9269612 L78.6309894,30.5455365 Z" id="Rectangle-Copy-15" fill="#0271A4"></path>
                                        <path d="M53.7637836,3.83228133 L66.981954,3.83228133 L66.981954,40.0135257 L53.7637836,40.0135257 C47.5781943,40.0135257 42.5637834,34.9991148 42.5637834,28.8135256 L42.5637834,15.0322815 C42.5637834,8.8466922 47.5781943,3.83228133 53.7637836,3.83228133 Z" id="Rectangle-Copy-16" fill="#0271A4"></path>
                                        <path d="M36.2834505,22.0919748 L30.0990766,22.0919748 C25.1506052,22.0919748 21.1390765,26.1035034 21.1390765,31.0519749 L21.1390765,55.2797162 C21.1390765,60.2281876 17.1275478,64.2397163 12.1790764,64.2397163 L-4.26325641e-13,64.2397163" id="Path-2-Copy" stroke="#0271A4" strokeWidth="3" strokeLinecap="round"></path>
                                    </g>
                                    <g id="Group-26-Copy-3" transform="translate(141.000000, 44.922904) scale(-1, -1) translate(-141.000000, -44.922904) translate(102.000000, 12.422904)">
                                        <path d="M73.646193,5.68434189e-14 L73.6468998,5.72875081e-14 C75.842784,5.68841307e-14 77.6228998,1.78011586 77.6228998,3.97600005 L77.6228998,39.9825211 C77.6228998,42.1784053 75.842784,43.9585212 73.6468998,43.9585212 L73.646193,43.9585212 C71.4503088,43.9585212 69.6701929,42.1784053 69.6701929,39.9825211 L69.6701929,3.97600005 C69.6701929,1.78011586 71.4503088,5.54704394e-14 73.646193,5.5067062e-14 Z" id="Rectangle-Copy-10" fill="#0271A4"></path>
                                        <path d="M37.8592259,18.3724076 L43.0118233,18.3724076 L43.0118233,25.5861136 L37.8592259,25.5861136 C36.9932434,25.5861136 36.2912259,24.8840961 36.2912259,24.0181136 L36.2912259,19.9404076 C36.2912259,19.0744251 36.9932434,18.3724076 37.8592259,18.3724076 Z" id="Rectangle-Copy-12" fill="#0271A4"></path>
                                        <path d="M53.7637836,3.83228133 L71.981954,3.83228133 L71.981954,40.0135257 L53.7637836,40.0135257 C47.5781943,40.0135257 42.5637834,34.9991148 42.5637834,28.8135256 L42.5637834,15.0322815 C42.5637834,8.8466922 47.5781943,3.83228133 53.7637836,3.83228133 Z" id="Rectangle-Copy-16" fill="#0271A4"></path>
                                        <path d="M36.2834505,22.0919748 L30.0990766,22.0919748 C25.1506052,22.0919748 21.1390765,26.1035034 21.1390765,31.0519749 L21.1390765,55.2797162 C21.1390765,60.2281876 17.1275478,64.2397163 12.1790764,64.2397163 L-4.26325641e-13,64.2397163" id="Path-2-Copy" stroke="#0271A4" strokeWidth="3" strokeLinecap="round"></path>
                                    </g>
                                    <g id="Group-27-Copy" transform="translate(51.000000, 0.000000)" fill="#0271A4">
                                        <g id="Group-28-Copy" transform="translate(37.239577, 14.441389) scale(-1, -1) translate(-37.239577, -14.441389) translate(0.239577, 0.941389)">
                                            <path d="M65.2097157,-1.21960455 L65.9136975,-1.22258459 C66.5322509,-1.22520301 67.0358101,-0.725889063 67.0384285,-0.107335677 C67.0384501,-0.102238203 67.0384369,-0.097140641 67.0383888,-0.0920433473 L66.8969944,14.916082 C66.8912233,15.52865 66.3943749,16.0229275 65.7817852,16.0255207 L65.0778034,16.0285007 C64.45925,16.0311191 63.9556908,15.5318052 63.9530724,14.9132518 C63.9530508,14.9081543 63.953064,14.9030568 63.953112,14.8979595 L64.0945065,-0.110165838 C64.1002776,-0.722733897 64.597126,-1.21701138 65.2097157,-1.21960455 Z" id="Rectangle" transform="translate(65.495750, 7.402958) rotate(128.000000) translate(-65.495750, -7.402958) "></path>
                                            <path d="M47.026621,9.83962804 L47.7445971,9.83738286 C48.3576191,9.83546588 48.8582768,10.3267382 48.8679597,10.9396867 L49.0846925,24.6593241 C49.0944629,25.2778059 48.6010047,25.7871048 47.982523,25.7968751 C47.9777939,25.7969498 47.9730643,25.7969946 47.9683346,25.7970094 L47.2503584,25.7992546 C46.6373365,25.8011715 46.1366787,25.3098992 46.1269958,24.6969508 L45.910263,10.9773133 C45.9004927,10.3588316 46.3939508,9.84953261 47.0124326,9.83976229 C47.0171617,9.83968758 47.0218912,9.83964283 47.026621,9.83962804 Z" id="Rectangle" transform="translate(47.497478, 17.818319) rotate(160.000000) translate(-47.497478, -17.818319) "></path>
                                            <path d="M23.8271381,11.8610786 L24.5451492,11.8633239 C25.1637051,11.8652582 25.6635757,12.3682648 25.6616414,12.9868207 C25.6616281,12.991064 25.6615908,12.9953071 25.6615293,12.9995499 L25.4799707,25.5260722 C25.4710783,26.1396013 24.9701765,26.631754 24.356586,26.6298353 L23.6385749,26.62759 C23.020019,26.6256557 22.5201484,26.122649 22.5220827,25.5040931 C22.5220959,25.4998499 22.5221333,25.4956067 22.5221948,25.4913639 L22.7037534,12.9648416 C22.7126458,12.3513126 23.2135476,11.8591598 23.8271381,11.8610786 Z" id="Rectangle" transform="translate(24.091862, 19.245457) rotate(200.000000) translate(-24.091862, -19.245457) "></path>
                                            <path d="M6.70472675,0.79414265 L7.40880037,0.797123084 C8.02735376,0.799741501 8.52666771,1.30330074 8.52404929,1.92185413 C8.52403316,1.92566571 8.52399756,1.92947719 8.52394252,1.93328841 L8.33133685,15.2681376 C8.32246788,15.8821716 7.82080513,16.3745517 7.20671258,16.3719522 L6.50263896,16.3689717 C5.88408557,16.3663533 5.38477163,15.8627941 5.38739004,15.2442407 C5.38740618,15.2404291 5.38744177,15.2366176 5.38749682,15.2328064 L5.58010248,1.89795728 C5.58897146,1.28392327 6.0906342,0.791543117 6.70472675,0.79414265 Z" id="Rectangle" transform="translate(6.955720, 8.583047) rotate(225.000000) translate(-6.955720, -8.583047) "></path>
                                        </g>
                                    </g>
                                    <g id="Group-27-Copy-2" transform="translate(88.500000, 96.000000) scale(1, -1) translate(-88.500000, -96.000000) translate(51.000000, 82.000000)" fill="#0271A4">
                                        <g id="Group-28-Copy" transform="translate(37.239577, 14.441389) scale(-1, -1) translate(-37.239577, -14.441389) translate(0.239577, 0.941389)">
                                            <path d="M65.2097157,-1.21960455 L65.9136975,-1.22258459 C66.5322509,-1.22520301 67.0358101,-0.725889063 67.0384285,-0.107335677 C67.0384501,-0.102238203 67.0384369,-0.097140641 67.0383888,-0.0920433473 L66.8969944,14.916082 C66.8912233,15.52865 66.3943749,16.0229275 65.7817852,16.0255207 L65.0778034,16.0285007 C64.45925,16.0311191 63.9556908,15.5318052 63.9530724,14.9132518 C63.9530508,14.9081543 63.953064,14.9030568 63.953112,14.8979595 L64.0945065,-0.110165838 C64.1002776,-0.722733897 64.597126,-1.21701138 65.2097157,-1.21960455 Z" id="Rectangle" transform="translate(65.495750, 7.402958) rotate(128.000000) translate(-65.495750, -7.402958) "></path>
                                            <path d="M47.026621,9.83962804 L47.7445971,9.83738286 C48.3576191,9.83546588 48.8582768,10.3267382 48.8679597,10.9396867 L49.0846925,24.6593241 C49.0944629,25.2778059 48.6010047,25.7871048 47.982523,25.7968751 C47.9777939,25.7969498 47.9730643,25.7969946 47.9683346,25.7970094 L47.2503584,25.7992546 C46.6373365,25.8011715 46.1366787,25.3098992 46.1269958,24.6969508 L45.910263,10.9773133 C45.9004927,10.3588316 46.3939508,9.84953261 47.0124326,9.83976229 C47.0171617,9.83968758 47.0218912,9.83964283 47.026621,9.83962804 Z" id="Rectangle" transform="translate(47.497478, 17.818319) rotate(160.000000) translate(-47.497478, -17.818319) "></path>
                                            <path d="M23.8271381,11.8610786 L24.5451492,11.8633239 C25.1637051,11.8652582 25.6635757,12.3682648 25.6616414,12.9868207 C25.6616281,12.991064 25.6615908,12.9953071 25.6615293,12.9995499 L25.4799707,25.5260722 C25.4710783,26.1396013 24.9701765,26.631754 24.356586,26.6298353 L23.6385749,26.62759 C23.020019,26.6256557 22.5201484,26.122649 22.5220827,25.5040931 C22.5220959,25.4998499 22.5221333,25.4956067 22.5221948,25.4913639 L22.7037534,12.9648416 C22.7126458,12.3513126 23.2135476,11.8591598 23.8271381,11.8610786 Z" id="Rectangle" transform="translate(24.091862, 19.245457) rotate(200.000000) translate(-24.091862, -19.245457) "></path>
                                            <path d="M6.70472675,0.79414265 L7.40880037,0.797123084 C8.02735376,0.799741501 8.52666771,1.30330074 8.52404929,1.92185413 C8.52403316,1.92566571 8.52399756,1.92947719 8.52394252,1.93328841 L8.33133685,15.2681376 C8.32246788,15.8821716 7.82080513,16.3745517 7.20671258,16.3719522 L6.50263896,16.3689717 C5.88408557,16.3663533 5.38477163,15.8627941 5.38739004,15.2442407 C5.38740618,15.2404291 5.38744177,15.2366176 5.38749682,15.2328064 L5.58010248,1.89795728 C5.58897146,1.28392327 6.0906342,0.791543117 6.70472675,0.79414265 Z" id="Rectangle" transform="translate(6.955720, 8.583047) rotate(225.000000) translate(-6.955720, -8.583047) "></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }

}
