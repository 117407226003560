import React, { Component } from 'react';
import {
    Modal,
} from 'reactstrap';
// css
import './Modal.css'
// hoc
import { withRouter } from 'react-router-dom';

export function withModal(WrappedComponent) {
    class HOC extends Component {
        constructor(props) {
            super(props);
            this.state = {
                modal: null,
                size: MODAL_SIZE.LG,
            }
        }

        setModal = (modal = null, size = MODAL_SIZE.LG) => {
            this.setState({
                modal,
                size,
            });
        }

        handelBackButton = () => {
            window.onpopstate = () => {
                if (this.state.modal) {
                    this.props.history.push(this.props.history.location.pathname);
                }
            }
        }

        render() {
            const { modal, size } = this.state;
            return (
                <div className="h-100 w-100">
                    <WrappedComponent
                        modalContent={modal}
                        showModal={(modal, size) => this.setModal(modal, size)}
                        closeModal={() => this.setModal()}
                        handelBackButton={this.handelBackButton}
                        {...this.props} />
                    <Modal
                        size={size}
                        isOpen={(modal !== null)}
                    >
                        {modal}
                    </Modal>
                </div>
            );
        }
    }

    return withRouter(HOC);
}

export const MODAL_SIZE = {
    SM: "sm",
    MD: "md",
    LG: "lg",
}