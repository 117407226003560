import React from 'react';
import PopupModal from '../Common/PopupModal/PopupModal';
import FacilityLocations from './FacilityLocations';
import { Spinner } from 'reactstrap';
// hoc
import { withModal, MODAL_SIZE } from '../../HOC/Modal';
import { axiosInstance } from '../../HOC/Communication';
// constants
import { URLS, ID } from '../../HOC/Communication/ApiUrls';
import { PARSING_KEYS } from '../../Constants/AppConstants';

class FacilityLocationsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.init();

        this.state = {
            buildings: null,
            floors: null,
            locations: null,
            campsNameAr: "",
            campsNameEn: "",
            buildingId: -1,
            floorId: -1,
            editingOnBuildingId: -100,
        }
    }

    /**
     * initialize metadata
     */
    init = () => {

        this.buildingsMetadata = [
            {
                label: 'facilityLocations.buildingNameEn',
                key: PARSING_KEYS.BUILDING.NAME_EN,
                className: 'title',
                col: 12,
                value: '',
                maxCharLimit: 100,
                isEditable: true,
                placeholder: 'placeholder.buildingNameEn',
                tooltip: true,
            },
            {
                label: 'facilityLocations.buildingNameAr',
                key: PARSING_KEYS.BUILDING.NAME_AR,
                className: 'title GeezaProRegular',
                col: 12,
                value: '',
                maxCharLimit: 100,
                isEditable: true,
                placeholder: 'placeholder.buildingNameAr',
                tooltip: true,
            },
        ]

        this.floorsMetadata = [
            {
                label: 'facilityLocations.floorNameEn',
                key: PARSING_KEYS.FLOOR.NAME_EN,
                className: 'title',
                col: 12,
                value: '',
                maxCharLimit: 100,
                isEditable: true,
                placeholder: 'placeholder.floorNameEn',
                tooltip: true,
            },
            {
                label: 'facilityLocations.floorNameAr',
                key: PARSING_KEYS.FLOOR.NAME_AR,
                className: 'url GeezaProRegular',
                col: 12,
                value: '',
                maxCharLimit: 100,
                isEditable: true,
                placeholder: 'placeholder.floorNameAr',
                tooltip: true,
            },
        ]

        this.locationsMetadata = [
            {
                label: 'facilityLocations.locationNameEn',
                key: PARSING_KEYS.LOCATION.NAME_EN,
                className: 'title',
                col: 12,
                value: '',
                maxCharLimit: 100,
                isEditable: true,
                placeholder: 'placeholder.locationNameEn',
                tooltip: true,
            },
            {
                label: 'facilityLocations.locationNameAr',
                key: PARSING_KEYS.LOCATION.NAME_AR,
                className: 'url GeezaProRegular',
                col: 12,
                value: '',
                maxCharLimit: 100,
                isEditable: true,
                placeholder: 'placeholder.locationNameAr',
                tooltip: true,
            },
        ]
    }

    /**
     * get building floor locations data and campus name after mount
     */
    componentDidMount() {
        this.axios = axiosInstance;
        this.getBuildingsFloorsLocations();
        this.getCampusName();
        this.props.handelBackButton();
    }

    /**
     * add ground floor to list
     * params floor list
     */
    addGroundFloor = (list) => {
        list.unshift({
            id: -1,
            flrNameEn: 'Ground Floor',
            flrNameAr: '',
            className: 'ground-floor'
        })
    }

    /**
     * error modal
     */
    showErrorModel = (message) => {
        this.props.showModal(
            <PopupModal
                isError
                message={message}
                onOkClick={this.props.closeModal}
            />
            , MODAL_SIZE.SM
        );
    }

    /**
     * get campus from params.campusId
     */
    getCampusName = () => {
        this.axios.get(URLS.CAMPUSES + this.props.match.params.campusId)
            .then(res => {
                if (!res.isError) {
                    const { campsNameEn, campsNameAr } = res.data.data;
                    this.setState({ campsNameEn, campsNameAr })
                }
            })
    }

    /**
     * get all data by params.campusId
     * result : get building list of campus and floors from first building and locations of ground floor
     */
    getBuildingsFloorsLocations = () => {
        this.props.showModal(
            <Spinner />
        );

        const URL = URLS.BUILDINGS_FLOORS_LOCATIONS.replace(ID.CAMPUS, this.props.match.params.campusId);
        this.axios.get(URL)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    this.setState({
                        buildingId: res.data.data.buildings[0].id,
                        floorId: (res.data.data.floors[0]) ? res.data.data.floors[0].id : -1,
                        buildings: res.data.data.buildings,
                        floors: res.data.data.floors,
                        locations: res.data.data.locations,
                        editingOnBuildingId: -100,
                        editingOnFloorId: -100,
                        editingOnLocationId: -100,
                    });
                    this.props.closeModal();
                }
                else {
                    this.setState({
                        buildings: [],
                        floors: [],
                        locations: [],
                    });
                }
            })
    }

    /**
     * get floors and locations data by building id
     * result floors list locations under ground floor
     */
    getFloorsLocations = (buildingId) => {
        this.props.showModal(
            <Spinner />
        );
        const URL = URLS.FLOORS_LOCATIONS
            .replace(ID.CAMPUS, this.props.match.params.campusId)
            .replace(ID.BUILDING, buildingId)
        this.axios.get(URL)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    let floorId = (res.data.data.floors[0]) ? res.data.data.floors[0].id : -1;
                    // get locations api if selected floor is not first & get locations of selected floor
                    if (buildingId === this.state.buildingId && this.state.floorId !== floorId) {
                        floorId = this.state.floorId;
                        this.getLocations(buildingId, this.state.floorId, 0, []);
                    }
                    this.setState({
                        buildingId,
                        floorId,
                        floors: res.data.data.floors,
                        locations: (buildingId === this.state.buildingId && this.state.floorId !== -1) ? this.state.locations : res.data.data.locations,
                        editingOnBuildingId: (buildingId !== this.state.editingOnBuildingId) ? -100 : buildingId,
                        editingOnFloorId: -100,
                        editingOnLocationId: -100,
                    });
                }
            })
    }

    /**
     * add building / floor / location
     */
    addData(link, data, onOkClick) {
        this.props.showModal(
            <Spinner />
        );
        this.axios.post(link, data)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    onOkClick(res.data.data);
                }
            })
    }

    /**
     * add building
     * params building data list
     */
    addBuilding = (list) => {
        const obj = {};
        obj[PARSING_KEYS.BUILDING.NAME_EN] = list.find(element => element.key === PARSING_KEYS.COMMON.NAME_EN).value;
        obj[PARSING_KEYS.BUILDING.NAME_AR] = list.find(element => element.key === PARSING_KEYS.COMMON.NAME_AR).value;
        this.addData(
            URLS.BUILDINGS
                .replace(ID.CAMPUS, this.props.match.params.campusId),
            obj,
            (data) => this.setState({ floorId: -1 }, this.getBuildings(data.id, [])),
        );
    }

    /**
     * add floor
     * params floor data list
     */
    addFloor = (list) => {
        const obj = {};
        obj[PARSING_KEYS.FLOOR.NAME_EN] = list.find(element => element.key === PARSING_KEYS.COMMON.NAME_EN).value;
        obj[PARSING_KEYS.FLOOR.NAME_AR] = list.find(element => element.key === PARSING_KEYS.COMMON.NAME_AR).value;
        this.addData(
            URLS.FLOORS
                .replace(ID.CAMPUS, this.props.match.params.campusId)
                .replace(ID.BUILDING, this.state.buildingId),
            obj,
            (data) => this.getFloors(this.state.buildingId, data.id, []),
        );
    }

    /**
     * add location
     * params location data list
     */
    addLocation = (list) => {
        const obj = {};
        obj[PARSING_KEYS.LOCATION.NAME_EN] = list.find(element => element.key === PARSING_KEYS.COMMON.NAME_EN).value;
        obj[PARSING_KEYS.LOCATION.NAME_AR] = list.find(element => element.key === PARSING_KEYS.COMMON.NAME_AR).value;
        this.addData(
            URLS.LOCATIONS
                .replace(ID.CAMPUS, this.props.match.params.campusId)
                .replace(ID.BUILDING, this.state.buildingId)
                .replace(ID.FLOOR, this.state.floorId),
            obj,
            (data) => this.getLocations(this.state.buildingId, this.state.floorId, data.id, [])
        );
    }

    /**
     * get building / floor / location list
     */
    getSpecificData = (url, buildingId, floorId, locationId, emptyObj) => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.get(url)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    let buildings, floors, locations;
                    if (locationId !== -2) {
                        buildings = null;
                        floors = null;
                        locations = res.data.data;
                    }
                    else if (floorId !== -2) {
                        buildings = null;
                        floors = res.data.data;
                        locations = emptyObj;
                    }
                    else {
                        buildings = res.data.data;
                        floors = (emptyObj) ? [] : null;
                        locations = emptyObj;
                    }
                    this.setState({
                        buildings: (buildings) ? buildings : this.state.buildings,
                        floors: (floors) ? floors : this.state.floors,
                        locations: (locations) ? locations : this.state.locations,
                        buildingId,
                        floorId: (floorId !== -2) ? floorId : this.state.floorId,
                        editingOnBuildingId: (buildingId !== this.state.editingOnBuildingId) ? -100 : buildingId,
                        editingOnFloorId: (floorId !== this.state.editingOnFloorId) ? -100 : floorId,
                        editingOnLocationId: -100,
                    })
                }
            })

    }

    /**
     * get buildings list
     */
    getBuildings = (buildingId, emptyObj = null) => {
        this.getSpecificData(
            URLS.BUILDINGS
                .replace(ID.CAMPUS, this.props.match.params.campusId),
            buildingId,
            -2,
            -2,
            emptyObj,
        )
    }

    /**
     * get floors list
     */
    getFloors = (buildingId, floorId, emptyObj = null) => {
        this.getSpecificData(
            URLS.FLOORS
                .replace(ID.CAMPUS, this.props.match.params.campusId)
                .replace(ID.BUILDING, buildingId),
            buildingId,
            floorId,
            -2,
            emptyObj,
        )
    }

    /**
     * get Locations list
     */
    getLocations = (buildingId, floorId, locationId, emptyObj = null) => {
        this.getSpecificData(
            URLS.LOCATIONS
                .replace(ID.CAMPUS, this.props.match.params.campusId)
                .replace(ID.BUILDING, buildingId)
                .replace(ID.FLOOR, floorId),
            buildingId,
            floorId,
            locationId,
            emptyObj,
        )
    }

    /**
     * get byilding / floor / location by id
     */
    getDataByUrl = (url, callback) => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.get(url)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    callback(res.data.data);
                }
            })
    }

    /**
     * get building by id
     * params building data, callback
     */
    getBuildingById = (data, callback) => {
        this.getDataByUrl(
            URLS.BUILDINGS.replace(ID.CAMPUS, this.props.match.params.campusId) + data.id,
            (data) => {
                const { buildings } = this.state;
                const index = buildings.findIndex(element => element.id === data.id);
                buildings[index] = data;
                this.setState({
                    buildings,
                    editingOnBuildingId: data.id,
                    editFloorById: -100,
                    editingOnLocationId: -100,
                });
                callback(data);
            }
        )
    }

    /**
     * get floor by id
     * params floor data, callback
     */
    getFloorById = (data, callback) => {
        this.getDataByUrl(
            URLS.FLOORS
                .replace(ID.CAMPUS, this.props.match.params.campusId)
                .replace(ID.BUILDING, this.state.buildingId)
            + data.id,
            (data) => {
                const { floors } = this.state;
                const index = floors.findIndex(element => element.id === data.id);
                floors[index] = data;
                this.setState({
                    floors,
                    editingOnBuildingId: -100,
                    editingOnFloorId: data.id,
                    editingOnLocationId: -100,
                });
                callback(data);
            }
        )
    }

    /**
     * get location by id
     * params location data, callback
     */
    getLocationById = (data, callback) => {
        this.getDataByUrl(
            URLS.LOCATIONS
                .replace(ID.CAMPUS, this.props.match.params.campusId)
                .replace(ID.BUILDING, this.state.buildingId)
                .replace(ID.FLOOR, this.state.floorId)
            + data.id,
            (data) => {
                const { locations } = this.state;
                const index = locations.findIndex(element => element.id === data.id);
                locations[index] = data;
                this.setState({
                    locations,
                    editingOnBuildingId: -100,
                    editingOnFloorId: -100,
                    editingOnLocationId: data.id,
                });
                callback(data);
            }
        )
    }

    /**
     * edit building by id
     * params data list, id, callback
     */
    editBuildingById = (data, index) => {
        this.props.showModal(
            <Spinner />
        );
        const requestObj = {}
        data.forEach(element => {
            requestObj[element.key] = element.value;
        })
        this.axios.put(URLS.BUILDINGS.replace(ID.CAMPUS, this.props.match.params.campusId) + index, {
            ...requestObj
        })
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    this.getBuildings(res.data.data.id, null);
                    this.setState({
                        editingOnBuildingId: -100,
                        editingOnFloorId: -100,
                        editingOnLocationId: -100,
                    })
                }
            });
    }

    /**
     * edit floor by id
     * params data list, id, callback
     */
    editFloorById = (data, index) => {
        this.props.showModal(
            <Spinner />
        );
        const requestObj = {}
        data.forEach(element => {
            requestObj[element.key] = element.value;
        })
        this.axios.put(
            URLS.FLOORS
                .replace(ID.CAMPUS, this.props.match.params.campusId)
                .replace(ID.BUILDING, this.state.buildingId)
            + index
            , {
                ...requestObj
            }
        )
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    this.getFloors(this.state.buildingId, res.data.data.id, null);
                }
                this.setState({
                    editingOnBuildingId: -100,
                    editingOnFloorId: -100,
                    editingOnLocationId: -100,
                })
            });
    }

    /**
     * edit location by id
     * params data list, id
     */
    editLocationById = (data, index, callback) => {
        this.props.showModal(
            <Spinner />
        );
        const requestObj = {}
        data.forEach(element => {
            requestObj[element.key] = element.value;
        })
        this.axios.put(
            URLS.LOCATIONS
                .replace(ID.CAMPUS, this.props.match.params.campusId)
                .replace(ID.BUILDING, this.state.buildingId)
                .replace(ID.FLOOR, this.state.floorId)
            + index
            , {
                ...requestObj
            }
        )
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    this.getLocations(this.state.buildingId, this.state.floorId, res.data.data.id, null);
                }
                this.setState({
                    editingOnBuildingId: -100,
                    editingOnFloorId: -100,
                    editingOnLocationId: -100,
                })
            });
    }


    /**
     * delete on url 
     */
    deleteByUrl = (url, callback) => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.delete(url)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    callback();
                }
            });
    }

    /**
     * delete building by id
     */
    deleteBuildingById = (data) => {
        this.deleteByUrl(
            URLS.BUILDINGS
                .replace(ID.CAMPUS, this.props.match.params.campusId)
            + data.id,
            this.getBuildingsFloorsLocations
        )
    }

    /**
     * delete floor by id
     */
    deleteFloorById = (data) => {
        this.deleteByUrl(
            URLS.FLOORS
                .replace(ID.CAMPUS, this.props.match.params.campusId)
                .replace(ID.BUILDING, this.state.buildingId)
            + data.id,
            () => this.setState({ floorId: -1 }, () => this.getFloorsLocations(this.state.buildingId)),
        )
    }

    /**
     * delete location by id
     */
    deleteLocationById = (data) => {
        this.deleteByUrl(
            URLS.LOCATIONS
                .replace(ID.CAMPUS, this.props.match.params.campusId)
                .replace(ID.BUILDING, this.state.buildingId)
                .replace(ID.FLOOR, this.state.floorId)
            + data.id,
            () => this.getLocations(this.state.buildingId, this.state.floorId, data.id),
        )
    }

    /**
     * set editing to param id
     */
    setEditingOn = (editingOnBuildingId, editingOnFloorId, editingOnLocationId) => {
        this.setState({ editingOnBuildingId, editingOnFloorId, editingOnLocationId })
    }

    render() {
        return (
            <FacilityLocations
                campsNameEn={this.state.campsNameEn}
                campsNameAr={this.state.campsNameAr}
                showModal={this.props.showModal}
                closeModal={this.props.closeModal}
                buildingId={this.state.buildingId}
                floorId={this.state.floorId}
                buildings={this.state.buildings}
                floors={this.state.floors}
                locations={this.state.locations}
                buildingsMetadata={this.buildingsMetadata}
                floorsMetadata={this.floorsMetadata}
                locationsMetadata={this.locationsMetadata}
                getFloorsLocations={this.getFloorsLocations}
                getLocations={this.getLocations}
                getBuildingById={this.getBuildingById}
                getFloorById={this.getFloorById}
                getLocationById={this.getLocationById}
                addBuilding={this.addBuilding}
                addFloor={this.addFloor}
                addLocation={this.addLocation}
                editBuildingById={this.editBuildingById}
                editFloorById={this.editFloorById}
                editLocationById={this.editLocationById}
                deleteBuildingById={this.deleteBuildingById}
                deleteFloorById={this.deleteFloorById}
                deleteLocationById={this.deleteLocationById}
                editingOnBuildingId={this.state.editingOnBuildingId}
                editingOnFloorId={this.state.editingOnFloorId}
                editingOnLocationId={this.state.editingOnLocationId}
                setEditingOn={this.setEditingOn}
            />
        )
    }
}

export default withModal(FacilityLocationsContainer);