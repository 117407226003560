import React from 'react';
import PropTypes from 'prop-types';

export default class Checked extends React.Component {

    static propTypes = {
        fillColor: PropTypes.string.isRequired,
    }

    render() {
        return (
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                <g id="ic_check" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Group-5" transform="translate(4.000000, 3.000000)">
                        <g id="Group-3">
                            <rect id="Rectangle" fill={this.props.fillColor} x="0" y="0" width="17" height="17" rx="4"></rect>
                            <g id="checked" transform="translate(4.000000, 5.000000)" fill="#FFFFFF">
                                <polygon id="Path" points="9.4785834 0 3.59421779 5.9461997 0.521416597 2.84113264 0 3.36803279 3.59421779 7 10 0.526900149"></polygon>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }

}
