import React from 'react'
// hoc
import { withNamespaces } from 'react-i18next';
//css
import './Footer.scss';

function Footer(props) {
    return (
        <div className="footer">
            {props.i18n.t("footer.text")}{props.version}
        </div>
    );
}

export default withNamespaces()(Footer);