import React, { Component } from 'react';

class ApprovalDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      isDropdownOpen: false,
      selecteDept:null
      
    };
    this.dropdownRef = React.createRef();
  }

  toggleDropdown = () => {
    this.setState(prevState => ({
      isDropdownOpen: !prevState.isDropdownOpen,
    }));
  };

  closeDropdown = () => {
    this.setState({ isDropdownOpen: false });
  };

  handleSearchChange = (event) => {
    this.setState({ searchTerm: event.target.value });
  };

  handleOptionSelect = (deptId) => {
    const { onDeptChange} = this.props;

    this.setState({
      isDropdownOpen: false,
      searchTerm: '',
      selecteDept:deptId
    });
    this.closeDropdown();
    onDeptChange(deptId);

  };

  handleClickOutside = (event) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
      this.closeDropdown();
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render() {
    const { deptList ,selectedDept} = this.props;
    const { searchTerm, isDropdownOpen,selecteDept } = this.state;

    const filteredDepts = deptList.filter(department =>
      department.deptName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Display "All" if selectedDept is "0" or null
    const selectedDeptName = selecteDept === 0
      ? "All"
      : deptList.find(dept => dept.deptId === selectedDept)?.deptName || "Select Department";

    return (
      <div className="custom-dropdown form-control input-field"  ref={this.dropdownRef} onClick={this.toggleDropdown}
      tabIndex={0}>
       <div
          className="selected-text"
          title={selectedDeptName} // Tooltip for the full text
        >
         {selectedDeptName}
        </div>
        {isDropdownOpen && (
          <div className="dropdown-body">
            <input
              type="text"
              placeholder="Search departments..."
              value={searchTerm}
              onChange={this.handleSearchChange}
              className="search-input"
              onClick={(e) => e.stopPropagation()} 
            />
            <div className="options">
              {filteredDepts.map((department, index) => (
                <div
                  key={index}
                  className="option"
                  onClick={() => this.handleOptionSelect(department.deptId)}
                >
                  {department.deptName}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ApprovalDropdown;
