import React from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
} from 'reactstrap';
import CustomList from '../Common/CustomList/CustomList';
// hoc
import { withNamespaces } from 'react-i18next';


class links extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editingOn: '',
            otherSettings: null,
        }
    }

    static propTypes = {
        showModal: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
        metadata: PropTypes.array.isRequired,
        commonLinks: PropTypes.array,
        getCommonLinks: PropTypes.func.isRequired,
        getCommonLinkByCode: PropTypes.func.isRequired,
        putCommonLinkByCode: PropTypes.func.isRequired,
    }

    static getDerivedStateFromProps(props, state) {
        return ({
            editingOn: state.editingOn,
            otherSettings: (state.editingOn) ? state.otherSettings : JSON.parse(JSON.stringify(props.otherSettings)),
        });
    }

    renderList = (list) => {
        return (
            <CustomList
                list={list}
                keys={this.props.metadata}
                onEditClick={this.props.getCommonLinkByCode}
                onEdited={this.props.putCommonLinkByCode}
                refreshData={this.props.getCommonLinks}
                showModal={this.props.showModal}
                closeModal={this.props.closeModal}
            />
        )
    }

    render() {
        return (
            <div className="container settings">
                <Row className="my-3">
                    <Col md={6}>
                        {this.renderList(this.props.commonLinks ? this.props.commonLinks.slice(0, parseInt((this.props.commonLinks.length + 1) / 2)) : null)}
                    </Col>
                    <Col md={6}>
                        {this.renderList(this.props.commonLinks ? this.props.commonLinks.slice(parseInt((this.props.commonLinks.length + 1) / 2)) : null)}
                    </Col>
                </Row>
            </div >
        );
    }
}

export default withNamespaces()(links);