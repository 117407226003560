import React from 'react';
import {
    Route,
    Switch,
} from "react-router-dom";
import Header from '../Header';
// constants
import { ROUTE } from '../../Constants/AppConstants';

function MainApp(props) {
    return (
        <div className="d-flex flex-column overflow-hidden h-100">
            <div className="header">
                <Header />
            </div>
            <div className="overflow-auto h-100">
                <Switch>
                    {
                        Object.keys(ROUTE).map((key, index) => {
                            const Component = ROUTE[key].COMPONENT;
                            return (
                                (ROUTE[key].PROTECTED)
                                    ? (
                                        <Route exact key={index} path={ROUTE[key].PATH} render={() => <Component {...props} />} />
                                    )
                                    : null
                            );
                        })
                    }
                </Switch>
            </div>
        </div>
    );
}

export default MainApp;