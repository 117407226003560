import React from 'react';
import PropsTypes from 'prop-types';
import {
    Row,
    Col,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    FormFeedback,
    Label,
    Input,
} from 'reactstrap';
// css
import './DisplayInputs.scss';
// hoc
import { withNamespaces } from 'react-i18next';

export const INVALID_TYPE = {
    VALID: 0,
    EMPTY: 'EMPTY',
    REGEX: 'REGEX',
    OVERFLOW: 'OVERFLOW',
    ERROR:"ERROR"
}

class DisplayInputs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            list: [],
            defaultList: [],
    
        }
    }

    static propTypes = {
        list: PropsTypes.array.isRequired, // each element in list must have label, md, value as keys
        defaultList: PropsTypes.array.isRequired,
    }

    static getDerivedStateFromProps(props, state) {
        if (state.list.length === 0) {
            return props;
        }
        props.defaultList.forEach((element, index) => {
            if (element.checkEmpty) {
                DisplayInputs.checkValidity(state.list[index]);
            }
        });
        return state;

    }

    static checkValidity = (element) => {
        element.invalid = INVALID_TYPE.VALID;
        if (element.value === '') {
            element.invalid = INVALID_TYPE.EMPTY;
        }
        else if (element.maxCharLimit && element.value.length > element.maxCharLimit) {
            element.invalid = INVALID_TYPE.OVERFLOW;
        }
        else if (element.regex) {
            const regex = new RegExp(element.regex);
            element.invalid = (!regex.test(element.value)) ? INVALID_TYPE.REGEX : 0;
        }else if(element.errorMessageZone){
            element.invalid = INVALID_TYPE.ERROR;
        }
    }

    onChange = (index, value) => {
        const { list } = this.state;
        const regex = new RegExp(list[index].regex);
        if (!list[index].allowValidInputsOnly || regex.test(value)) {
            list[index].value = value;
        }
        DisplayInputs.checkValidity(list[index]);
        this.setState({ list });
    }

    render() {
        return (
            <div className="display-inputs">
                <Row>
                    {
                        this.state.list.map((element, index) => {
                            if ((element.isEditable) || (this.props.isAddNew && element.add)) {
                                return (
                                    <Col key={index} md={element.md} className="mt-1">
                                        <FormGroup className={`${(element.prepend) ? 'input-prepend' : ''} ${element.className}`}>
                                            <Label className="m-0">{this.props.i18n.t(element.label)}
                                            {element.key==="range"? " (in meters)":null}
                                            </Label>
                                            <div className="d-flex">
                                                {
                                                    (element.prepend)
                                                        ? (
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>{element.prepend}</InputGroupText>
                                                            </InputGroupAddon>
                                                        )
                                                        : null
                                                }
                                                <Input
                                                    bsSize="sm"
                                                    placeholder={this.props.i18n.t(element.placeholder)}
                                                    value={element.value || ''}
                                                    invalid={this.state.defaultList[index].checkEmpty && (element.invalid) ? true : false}
                                                    onChange={event => this.onChange(index, event.target.value)}
                                                />
                                            </div>
                                            <FormFeedback className={(element.invalid && this.state.defaultList[index].checkEmpty) ? 'd-block' : 'd-none'}>
                                                {
                                                    element.invalid === INVALID_TYPE.EMPTY
                                                        ? (
                                                            this.props.i18n.t('inlineInputErrors.empty')
                                                                .replace('<name>', this.props.i18n.t(element.label))
                                                        )
                                                        : (
                                                            element.invalid === INVALID_TYPE.REGEX
                                                                ? (
                                                                    this.props.i18n.t('inlineInputErrors.regex')
                                                                        .replace('<name>', this.props.i18n.t(element.label))
                                                                )
                                                                : (
                                                                    element.invalid === INVALID_TYPE.OVERFLOW
                                                                        ? (
                                                                            this.props.i18n.t('inlineInputErrors.overflow')
                                                                                .replace('<name>', this.props.i18n.t(element.label))
                                                                                .replace('<limit>', element.maxCharLimit)
                                                                        )
                                                                        :  element.invalid === INVALID_TYPE.ERROR?this.props.i18n.t(element.errorMessageZone):
                                                                        this.props.i18n.t('inlineInputErrors.error')
                                                                )
                                                                
                                                        )
                                                }
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                )
                            }
                            return null;
                        })
                    }
                </Row>
            </div>
        )
    }
}


export default withNamespaces()(DisplayInputs);
