import React from 'react';
import axios from 'axios';
import { URLS } from './ApiUrls';
import Storage from '../../Storage';
import { errorLookup } from './ErrorLookup';
import PopupModal from '../../Components/Common/PopupModal/PopupModal';
// hoc
import { withRouter } from 'react-router-dom';
import { withModal, MODAL_SIZE } from '../Modal';
import { CRITICAL_ERROR_CODES, ROUTE, TIMEOUT_DURATION } from '../../Constants/AppConstants';
// assets
import Disconnected from '../../Assets/Disconnected';

export let axiosInstance = {};

/**
 * this is communication hoc
 * providex axios to component
 * use this HOC at higher level because it dosent pass recieved props to child
 * except version
 */
export function withCommunication(WrappedComponent) {
    class HOC extends React.Component {
        constructor(props) {
            super(props);

            this.axios = axios.create({
                baseURL: process.env.REACT_APP_SERVER_URL + URLS.BASE_URL,
            })

            axiosInstance = this.axios;

            this.requestInterceptor = this.axios.interceptors.request.use(
                (config) => {
                    config.withCredentials = true;
                    config.timeout = TIMEOUT_DURATION;
                    return config;
                },
                (error) => {
                    return Promise.reject(error);
                }
            );

            this.responseInterceptor = this.axios.interceptors.response.use(
                res => {
                    if (res.data.error) {
                        const errorObj = errorLookup(res.data.error);
                        this.showErrorModal(errorObj.code, errorObj.message);
                        return errorObj;
                    }
                    res.isError = false;
                    return res;
                },

                error => {
                    if (error.response) {
                        const errorObj = errorLookup(error.response.data.error);
                        this.showErrorModal(errorObj.code, errorObj.message);
                        return errorObj;
                    }
                    else {
                        const errorObj = errorLookup(error.toJSON());
                        this.showErrorModal(errorObj.code, errorObj.message);
                        return errorObj;
                    }
                }
            );
        }

        componentDidMount() {
            window.addEventListener('load', () => {
                window.addEventListener('online', this.props.closeModal);
                window.addEventListener('offline', this.showNetworkUnavailableModal);
            })
        }

        showNetworkUnavailableModal = () => {
            this.props.showModal(
                <PopupModal
                    title='errorMessages.networkError'
                    bodyClasses='network-disconnected-body'
                    body={
                        <div>
                            <Disconnected />
                        </div>
                    }
                    message='errorMessages.noInternet'
                />
                , MODAL_SIZE.MD
            )
        }

        showErrorModal = (code, message) => {
            let previousOnPopState = window.onpopstate;
            this.props.handelBackButton();
            this.props.showModal(
                <PopupModal
                    isError
                    title='modal.error'
                    message={message}
                    onOkClick={() => {
                        this.props.closeModal();
                        window.onpopstate = previousOnPopState;
                        if (CRITICAL_ERROR_CODES.find(element => element === code)) {
                            window.onpopstate = null;
                            Storage.set(Storage.keys.AUTH, {});
                            this.props.history.push(ROUTE.LOGIN.PATH);
                        }
                    }}
                />
                , MODAL_SIZE.SM
            )
        }

        componentWillUnmount() {
            this.axios.interceptors.request.eject(this.requestInterceptor);
            this.axios.interceptors.response.eject(this.responseInterceptor);
        }

        render() {
            return (
                <WrappedComponent
                    axios={this.axios}
                    version={this.props.version}
                />
            );
        }
    }

    return withRouter(withModal(HOC));
}