import React from 'react';
// css
import './CustomCard.scss';


class CustomCard extends React.Component {
    render() {
        return (
            <div className="custom-card">
                <div className="custom-card-header">
                    {this.props.header}
                </div>
                <div className="custom-card-body">
                    {this.props.body}
                </div>
            </div>
        );
    }
}

export default CustomCard;