//import { Suspense } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import Banners from './Banners';
import { Spinner } from 'reactstrap';
//hoc
import { withModal } from '../../HOC/Modal';
import { axiosInstance } from '../../HOC/Communication';
// constants
import { URLS } from '../../HOC/Communication/ApiUrls';
import { PARSING_KEYS, BANNERS_URL } from '../../Constants/AppConstants';

/**
 * Banners container 
 */

class BannersContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bannersurl: BANNERS_URL,
        }
    }

    static propTypes = {
        showModal: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
    }

    /**
     * get Banners data after mount
     */
    componentDidMount() {
        this.axios = axiosInstance;
        this.getAllData();
        this.props.handelBackButton();
    }

    /**
     * get required data 
     */
    getAllData = () => {
        this.responseFlag = false;
        this.getBannersUrl();
    }

    /**
     * GET Banners Data
     */
    getBannersUrl = () => {
        this.props.showModal(
           <Spinner />
        );
        this.axios.get(URLS.SETTINGS)
            .then(res => {
                // if (this.responseFlag)
                //     this.props.closeModal();
                this.responseFlag = true;
                if (!res.isError) {
                    const { bannersurl } = this.state;
                    const { SETTINGS } = PARSING_KEYS;
                    const { data } = res.data;
                    bannersurl.forEach(listElement => {
                        const resElement = data.find(dataElement => dataElement[SETTINGS.ID] === listElement[SETTINGS.ID])
                        if (resElement) {
                            listElement[SETTINGS.VALUE] = resElement[SETTINGS.VALUE].split(',');
                        }
                    })
                    this.setState({ bannersurl });
                    this.props.closeModal(); 
                }
                else {
                    this.setState({
                        bannersurl: [],
                    });
                    this.props.closeModal(); 
                }
 
            })  
            .catch(error => {
                console.error('Error fetching data:', error);
                this.setState({
                    bannersurl: [],
                });
                this.props.closeModal(); 
            });
    }


    /**
     * get Banners by ID
     */
    getBannersUrlById = (id, callback) => {
        this.props.showModal(
            <Spinner />
        );

        const url = URLS.SETTINGS + URLS.CONFIDS + id
        this.axios.get(url)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    const { SETTINGS } = PARSING_KEYS;
                    const { bannersurl } = this.state;
                    const index = bannersurl.findIndex(element => element[SETTINGS.ID] === id)
                    bannersurl[index][SETTINGS.VALUE] = res.data.data.find(element => element[SETTINGS.ID] === id)[SETTINGS.VALUE];
                    this.setState({ bannersurl })
                    callback(bannersurl);
                }
            })
    }

    /**
     * PUT
     */
    putBannersUrlById = (requestBody) => {
        this.props.showModal(
            <Spinner />
        );
        
        this.axios.put(URLS.SETTINGS,
            [
                requestBody,
            ]
        )
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    this.getBannersUrl();
                    console.log("PUT request succesfull for this data:", requestBody);
                }
            })
    }

    /**
    * Delete a specific banner URL by ID and URL
    */

    deleteBannerUrlById = (id, urlToDelete) => {
        console.log('Deleting URL:', id, urlToDelete);
            //this.props.showModal(<Spinner />);
        
            const { bannersurl } = this.state;
            const { SETTINGS } = PARSING_KEYS;
            const index = bannersurl.findIndex(element => element[SETTINGS.ID] === id);
        
            if (index !== -1) {
                const value = bannersurl[index][SETTINGS.VALUE];
        
                if (Array.isArray(value)) {
                    const urlIndex = value.indexOf(urlToDelete);
        
                    if (urlIndex !== -1) {
                        value.splice(urlIndex, 1);
                        const updatedValue = value.join(',');
                        bannersurl[index][SETTINGS.VALUE] = updatedValue;
        
                        this.putBannersUrlById(bannersurl[index]);
                    } else {
                        console.error('URL not found in array:', urlToDelete);
                    }
                } else {
                    console.error('Value is not an array:', value);
                }
            } else {
                console.error('Element not found with ID:', id);
            }
    }
    

    render() {
        const { SETTINGS } = PARSING_KEYS;
        const { bannersurl } = this.state;
        const filteredBannersUrl = [];

        bannersurl.forEach(element => {
            if (typeof element[SETTINGS.VALUE] !== 'undefined') {
                 filteredBannersUrl.push(element);
            }
        })
        return (
            <Banners
                showModal={this.props.showModal}
                closeModal={this.props.closeModal}
                bannersurl={filteredBannersUrl} // {this.state.otherSettings}
                getBannersUrl={this.getBannersUrl}
                getBannersUrlById={this.getBannersUrlById}
                putBannersUrlById={this.putBannersUrlById}
                deleteBannerUrlById={this.deleteBannerUrlById} 
            />
        );
    }
}

export default withModal(BannersContainer);
