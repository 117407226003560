import React from 'react';
import PropTypes from 'prop-types';
import Links from './CommonLinks';
import { Spinner } from 'reactstrap';
//hoc
import { withModal } from '../../HOC/Modal';
import { axiosInstance } from '../../HOC/Communication';
// constants
import { URLS } from '../../HOC/Communication/ApiUrls';
import { REGEX, PARSING_KEYS, SETTINGS_CONFIG_LIST } from '../../Constants/AppConstants';

/**
 * Settings container 
 */

class LinksContainer extends React.Component {

    constructor(props) {
        super(props);

        this.init();
        this.state = {
            commonLinks: null,
            otherSettings: SETTINGS_CONFIG_LIST,
        }

    }

    static propTypes = {
        showModal: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
    }

    /**
     * init axios and metadata
     */
    init = () => {
        this.metadata = [
            {
                key: 'linkName',
                label: 'a',
                md: '12',
                isEditable: false,
                className: 'link-name',
            },
            {
                key: 'linkUrl',
                label: 'url',
                md: '12',
                maxCharLimit: 200,
                isEditable: true,
                className: 'link-url',
                regex: REGEX.LINK,
                checkEmpty: true,
                placeholder: 'placeholder.link',
                tooltip: true,
            },
        ]
    }

    /**
     * get settings data after mount
     */
    componentDidMount() {
        this.axios = axiosInstance;
        this.getAllData();
        this.props.handelBackButton();
    }

    /**
     * get required data (common links and other settings data)
     */
    getAllData = () => {
        this.responseFlag = false;
        this.getCommonLinks();
        this.getOtherSettings();
    }

    /**
     * get common links data
     */
    getCommonLinks = () => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.get(URLS.COMMON_LINKS)
            .then(res => {
                if (this.responseFlag)
                    this.props.closeModal();
                this.responseFlag = true;
                if (!res.isError) {
                    this.setState({ commonLinks: res.data.data })
                }
                else {
                    this.setState({
                        commonLinks: [],
                    });
                }
            })
    }

    /**
     * get other settings data
     */
    getOtherSettings = () => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.get(URLS.SETTINGS)
            .then(res => {
                if (this.responseFlag)
                    this.props.closeModal();
                this.responseFlag = true;
                if (!res.isError) {
                    const { otherSettings } = this.state;
                    const { SETTINGS } = PARSING_KEYS;
                    const { data } = res.data;
                    otherSettings.forEach(listElement => {
                        const resElement = data.find(dataElement => dataElement[SETTINGS.ID] === listElement[SETTINGS.ID])
                        if (resElement) {
                            listElement[SETTINGS.VALUE] = resElement[SETTINGS.VALUE];
                        }
                    })
                    this.setState({ otherSettings });
                }
                else {
                    this.setState({
                        otherSettings: [],
                    });
                }
            })
    }

    /**
     * get common link by code
     * parameters link element, callback after successful get operation
     */
    getCommonLinkByCode = (element, callback) => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.get(URLS.COMMON_LINKS + element.linkCode)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    callback(res.data.data);
                }
            })
    }

    /**
     * get other settngs by code
     */
    getOtherSettingById = (id, callback) => {
        this.props.showModal(
            <Spinner />
        );

        const url = URLS.SETTINGS + URLS.CONFIDS + id
        this.axios.get(url)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    const { SETTINGS } = PARSING_KEYS;
                    const { otherSettings } = this.state;
                    const index = otherSettings.findIndex(element => element[SETTINGS.ID] === id)
                    otherSettings[index][SETTINGS.VALUE] = res.data.data.find(element => element[SETTINGS.ID] === id)[SETTINGS.VALUE];
                    this.setState({ otherSettings })
                    callback(otherSettings);
                }
            })
    }

    /**
     * edit specific link in common links by id
     * parameters link element, edited values, link status
     * on link changed call this function
     */
    putCommonLinkByCode = (element, list, status) => {
        this.props.showModal(
            <Spinner />
        );
        let url = element.linkUrl;
        if (list)
            url = (list.find(element => element.key === 'linkUrl')).value;
        this.axios.put(URLS.COMMON_LINKS + element.linkCode,
            {
                "linkUrl": url,
                "status": status,
            })
            .then((res) => {
                this.props.closeModal();
                if (!res.isError) {
                    this.getCommonLinks();
                }
            })
    }

    /**
     * edit specific link in other settings by id
     */
    putOtherSettingById = (requestBody) => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.put(URLS.SETTINGS,
            [
                requestBody,
            ]
        )
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    this.getOtherSettings();
                }
            })
    }

    render() {
        const { SETTINGS } = PARSING_KEYS;
        const { otherSettings } = this.state;
        const filteredSettings = [];
        otherSettings.forEach(element => {
            if (typeof element[SETTINGS.VALUE] !== 'undefined') {
                filteredSettings.push(element);
            }
        })
        return (
            <Links
                showModal={this.props.showModal}
                closeModal={this.props.closeModal}
                metadata={this.metadata}
                commonLinks={this.state.commonLinks}
                otherSettings={filteredSettings} // {this.state.otherSettings}
                getCommonLinks={this.getCommonLinks}
                getOtherSettings={this.getOtherSettings}
                getCommonLinkByCode={this.getCommonLinkByCode}
                getOtherSettingById={this.getOtherSettingById}
                putCommonLinkByCode={this.putCommonLinkByCode}
                putOtherSettingById={this.putOtherSettingById}
            />
        );
    }
}

export default withModal(LinksContainer);