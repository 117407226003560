import React from 'react';
import PropTypes from 'prop-types';

export default class Remove extends React.Component {

    static propTypes = {
        fillColor: PropTypes.string.isRequired,
    }

    render() {
        return (
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                <g id="ic_remove" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Group-3" transform="translate(2.000000, 2.000000)">
                        <circle id="Oval" fill={this.props.fillColor} cx="10" cy="10" r="10"></circle>
                        <g id="Group-5" transform="translate(10.500000, 10.500000) rotate(-315.000000) translate(-10.500000, -10.500000) translate(5.000000, 5.000000)" fill="#FFFFFF">
                            <rect id="Rectangle" x="4.8125" y="0" width="1.375" height="11"></rect>
                            <polygon id="Rectangle" transform="translate(5.500000, 5.500000) rotate(-90.000000) translate(-5.500000, -5.500000) " points="4.8125 -8.8817842e-16 6.1875 -8.8817842e-16 6.1875 11 4.8125 11"></polygon>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }

}
