export default class Storage {
    static keys = {
        AUTH: 'app-gateway',
    }

    static get(...params) {
        return (JSON.parse(sessionStorage.getItem(...params)))
    }

    static set(key, ...params) {
        sessionStorage.setItem(key, JSON.stringify(...params))
    }
}