import React from 'react';
import {
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Button,
} from 'reactstrap';
// hoc
import { withNamespaces } from 'react-i18next';
// css
import './Login.scss';

// assets
import LoginImage from '../../Assets/LoginImage.jpg';

// constants
import { ENTER_CHAR_CODE } from '../../Constants/AppConstants';

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userId: '',
            password: '',
        }
    }

    handleEnter = (event) => {
        const { userId, password } = this.state;
        if (event.charCode === ENTER_CHAR_CODE && userId !== '' && password !== '') {
            this.props.login(this.state.userId, this.state.password)
        }
    }

    render() {
        return (
            <div className="container login">
                <Row className="justify-content-center">
                    <div className="logo">
                        <img
                            src={LoginImage}
                            alt='logo'
                        />
                    </div>
                    <div className="input-container">
                        <div>
                            <Row>
                                <Col className="login-text">{this.props.i18n.t("login.login")}</Col>
                            </Row>
                            <form>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>{this.props.i18n.t("login.userId")}</Label>
                                            <Input
                                                autoComplete="false"
                                                type="text"
                                                placeholder={this.props.i18n.t("login.userIdPlaceholder")}
                                                onKeyPress={this.handleEnter}
                                                onChange={event => {
                                                    this.setState({ userId: event.target.value });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>{this.props.i18n.t("login.password")}</Label>
                                            <Input
                                                autoComplete="false"
                                                type="password"
                                                placeholder={this.props.i18n.t("login.passwordPlaceholder")}
                                                onKeyPress={this.handleEnter}
                                                onChange={event => {
                                                    this.setState({ password: event.target.value });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </form>
                            <Row className="p-0">
                                <Col>
                                    <span className="forgot-password" onClick={this.props.forgotPassword}>
                                        {this.props.i18n.t('login.forgotPassword')}
                                    </span>
                                </Col>
                            </Row>
                            <Row className="p-0">
                                <Col className="d-flex">
                                    <div className="w-100" />
                                    <Button
                                        color="secondary"
                                        onClick={() => {
                                            this.props.login(this.state.userId, this.state.password);
                                        }}
                                        disabled={this.state.userId === '' || this.state.password === ''}
                                    >
                                        {this.props.i18n.t("login.login")}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Row>
            </div>
        );
    }
}

export default withNamespaces()(Login);