import React from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    Button,
    UncontrolledTooltip,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import AddCampusModal from './AddCampusModal/AddCampusModal';
import AddEditModal from '../Common/AddEditModal/AddEditModal';
import PopupModal from '../Common/PopupModal/PopupModal';
import CustomCard from '../Common/CustomCard/CustomCard';
// hoc
import { withNamespaces } from 'react-i18next';
import { MODAL_SIZE } from '../../HOC/Modal';
// css
import Styles from './FacilityCampus.scss';
// assets
import Delete from '../../Assets/Delete';
import Edit from '../../Assets/Edit';
// constants
import { ROUTE } from '../../Constants/AppConstants';

class FacilityCampus extends React.Component {

    static propTypes = {
        list: PropTypes.array,
        metadata: PropTypes.array.isRequired,
        showModal: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
    }

    showAddModal = () => {
        this.props.showModal(
            <AddCampusModal
                i18n={this.props.i18n}
                onCancel={this.props.closeModal}
                onSave={this.props.addCampus}
            />
        );
    }

    editCampus = (data) => {
        this.props.onEditClick(
            data,
            (data) => {
                const list = JSON.parse(JSON.stringify(this.props.metadata));
                list.forEach(element => {
                    element.value = data[element.key]
                })
                this.props.showModal(
                    <AddEditModal
                        list={list}
                        modalHeader='facilityLocations.campus'
                        onSaveClick={() => this.props.editCampus(list, data.id)}
                        onCancelClick={() => {
                            this.props.closeModal();
                            this.props.getCampuses();
                        }}
                    />
                    , MODAL_SIZE.MD
                );
            }
        );
    }

    deleteCampus = (element) => {
        this.props.showModal(
            <PopupModal
                title='modal.confirmation.confirmDelete'
                message='modal.confirmation.deleteCampusMessage'
                onConfirmationClick={() => this.props.deleteCampusById(element.id)}
                onCancelClick={this.props.getCampuses}
            />
            , MODAL_SIZE.SM
        );
    }

    render() {
        return (
            <div className="container facility-campus">
                <Row className="flex-shrink-0">
                    <Col className="d-flex pt-3">
                        <Button
                            className="margin-inline-start-auto"
                            onClick={() => this.showAddModal()}
                        >
                            {this.props.i18n.t('facilityLocations.addCampus')}
                        </Button>
                    </Col>
                </Row>
                {
                    (this.props.list)
                        ? (
                            (this.props.list.length === 0)
                                ? (
                                    <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                                        {this.props.i18n.t('common.noDataAvailable')}
                                    </div>
                                )
                                : (
                                    <Row className="campus-list">
                                        {
                                            this.props.list.map((element, index) => {
                                                return (
                                                    <Col
                                                        key={index}
                                                        md={3}
                                                        className="my-3"
                                                    >
                                                        <CustomCard
                                                            body={
                                                                <div className="py-3 px-1">
                                                                    {
                                                                        this.props.metadata.map(metadata => {
                                                                            if (metadata.isVisible !== false) {
                                                                                return (
                                                                                    <Col key={metadata.key} md={metadata.md}>
                                                                                        <div className={`d-flex ${metadata.className}`}>
                                                                                            {
                                                                                                (metadata.icon)
                                                                                                    ? (
                                                                                                        <span className="mr-1">
                                                                                                            <metadata.icon
                                                                                                                fillColor={Styles.textLightColor}
                                                                                                            />
                                                                                                        </span>
                                                                                                    )
                                                                                                    : null
                                                                                            }
                                                                                            <span className="d-block text-truncate">
                                                                                                <span id={`UncontrolledTooltip-${index}-${metadata.key}`} className="d-block text-truncate">{element[metadata.key]}</span>
                                                                                                {
                                                                                                    (metadata.tooltip)
                                                                                                        ? (
                                                                                                            <UncontrolledTooltip className={metadata.className} target={`UncontrolledTooltip-${index}-${metadata.key}`}>
                                                                                                                {element[metadata.key]}
                                                                                                            </UncontrolledTooltip>
                                                                                                        )
                                                                                                        : null
                                                                                                }
                                                                                            </span>
                                                                                        </div>
                                                                                    </Col>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return null;
                                                                            }
                                                                        })
                                                                    }
                                                                    <Col>
                                                                        <Link to={ROUTE.FACILITY_CAMPUS.PATH + element.id}>
                                                                            <Button>
                                                                                {this.props.i18n.t('facilityLocations.viewMore')}
                                                                            </Button>
                                                                        </Link>
                                                                        <Button
                                                                            className="btn-icon"
                                                                            onClick={() => this.editCampus(element)}
                                                                        >
                                                                            <Edit
                                                                                fillColor={Styles.selectionColor}
                                                                            />
                                                                        </Button>
                                                                        <Button
                                                                            className="btn-icon"
                                                                            onClick={() => this.deleteCampus(element)}
                                                                        >
                                                                            <Delete
                                                                                fillColor={Styles.selectionColor}
                                                                            />
                                                                        </Button>
                                                                    </Col>
                                                                </div>
                                                            }
                                                        />
                                                    </Col>
                                                );
                                            })
                                        }
                                    </Row>
                                )
                        )
                        : null
                }
            </div>
        );
    }
}

export default withNamespaces()(FacilityCampus);