import React from 'react';
import PropTypes from 'prop-types';
import {
    ListGroup,
    ListGroupItem,
    FormGroup,
    FormFeedback,
    Input,
    UncontrolledTooltip,
} from 'reactstrap';
import PopupModal from '../PopupModal/PopupModal';
import AddEditModal from '../AddEditModal/AddEditModal';
// hoc
import { withNamespaces } from 'react-i18next';
import { MODAL_SIZE } from '../../../HOC/Modal';
// css
import Styles from './CustomList.scss';
// assets
import Delete from '../../../Assets/Delete';
import Edit from '../../../Assets/Edit';
import Checked from '../../../Assets/Checked';
import Unchecked from '../../../Assets/Unchecked';
import Cancel from '../../../Assets/Cancel';
import Ok from '../../../Assets/Ok';
// constants
import { REGEX } from '../../../Constants/AppConstants';
import { INVALID_TYPE } from '../DisplayInputs/DisplayInputs';


class CustomList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editList: [{}, {}]
        }
    }

    static propTypes = {
        showModal: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
        keys: PropTypes.array.isRequired,
        list: PropTypes.array,
        selectedIndex: PropTypes.number,
        setSelectedIndex: PropTypes.func,
        onEdited: PropTypes.func,
        onEditClick: PropTypes.func,
        OnDelete: PropTypes.func,
        inlineEdit: PropTypes.bool,
        clear: PropTypes.bool,
    };

    static defaultProps = {
        onEdited: null,
        OnDelete: null,
        setSelectedIndex: null,
        selectedIndex: -100,
        inlineEdit: false,
        clear: false,
        onEditClick: (data, callback) => callback(data),
    }

    showModal = (title, message, onConfirmation) => {
        this.props.showModal(
            <PopupModal
                title={title}
                message={message}
                onCancelClick={this.props.closeModal}
                onConfirmationClick={onConfirmation}
            />
            , MODAL_SIZE.SM
        )
    }

    showDeleteModal = (data) => {
        this.showModal(
            'modal.confirmation.confirmDelete',
            this.props.deleteModalMessage,
            () => {
                this.props.closeModal();
                this.props.onDelete(data)
            }
        );
    }

    toggleStatus = (data) => {
        const status = data.status ? 0 : 1;
        this.props.onEdited(data, null, status);
        this.props.closeModal();
    }

    showDeactivationModal = (data) => {
        this.showModal(
            'modal.confirmation.confirmDeactivation',
            'modal.confirmation.deactivateLinkMessage',
            () => this.toggleStatus(data),
        );
    }

    showActivationModal = (data) => {
        this.showModal(
            'modal.confirmation.confirmActivation',
            'modal.confirmation.activateLinkMessage',
            () => this.toggleStatus(data),
        );
    }

    onEditClick = (data) => {
        this.props.onEditClick(
            data,
            (data) => {
                if (this.props.inlineEdit) {
                    const editList = JSON.parse(JSON.stringify(this.props.keys))
                    editList.forEach(element => element.value = data[element.key]);
                    this.setState({
                        editList,
                    });
                }
                else {
                    const list = JSON.parse(JSON.stringify(this.props.keys));
                    list.forEach(element => element.value = data[element.key]);
                    const element = list.find(element => element.key === 'linkUrl')
                    if (element) {
                        element.label = data['linkName'];
                    }
                    this.props.showModal(
                        <AddEditModal
                            list={list}
                            modalHeader='modal.editLink'
                            onSaveClick={() => {
                                this.props.onEdited(data, list, data.status);
                                this.props.closeModal();
                            }}
                            onCancelClick={() => {
                                this.props.closeModal();
                                this.props.refreshData();
                            }}
                            clear={this.props.clear}
                            isEmptyAllowed={false}
                        />
                        , MODAL_SIZE.MD
                    )
                }
            },
        )
    }

    onOkClick = (event) => {
        event.stopPropagation();
        let isValid = true;
        let isDifferent = false;
        const previousElement = this.props.list.find(element => element.id === this.props.editingOn);
        this.state.editList.forEach(element => {
            element.value = element.value.replace(REGEX.INBETWEEN_SPACES, ' ').trim();
            if (element.invalid) {
                isValid = false;
            }
            if (element.value !== previousElement[element.key]) {
                isDifferent = true;
            }
        });
        if (!isDifferent) {
            this.props.setEditingOn(-100);
            return;
        }
        if (isValid) {
            this.props.onEdited(
                this.state.editList,
                this.props.editingOn,
            );
        }
    }

    onInlineChangeEvent = (value, index, maxCharLimit) => {
        const { editList } = this.state;
        editList[index].invalid = INVALID_TYPE.VALID;
        if (value === '') {
            editList[index].invalid = INVALID_TYPE.EMPTY;
        }
        if (value.length > maxCharLimit) {
            editList[index].invalid = INVALID_TYPE.OVERFLOW;
        }
        editList[index].value = value
        this.setState({ editList });
    }

    renderSpan = (key, className, text, tooltip) => {
        if (!text) {
            text = this.props.i18n.t('common.noDataAvailable')
        }
        return (
            <span key={key} className={`d-flex ${className}`}>
                <span id={`UncontrolledTooltip-${key}`} className="d-block text-truncate">{text}</span>
                {
                    (tooltip)
                        ? (
                            <UncontrolledTooltip className={className} target={`UncontrolledTooltip-${key}`}>
                                {text}
                            </UncontrolledTooltip>
                        )
                        : null
                }
            </span>
        )
    }

    render() {
        if (this.props.list === null) {
            return (
                <div className="custom-list" />
            );
        }
        else if (this.props.list.length === 0) {
            return (
                <div className="custom-list h-100">
                    {this.props.i18n.t('common.noDataAvailable')}
                </div>
            );
        }
        return (
            <ListGroup id="list" className="custom-list">
                {
                    this.props.list.map((element) => {
                        let className = (this.props.editingOn === element.id) ? 'editing ' : '';
                        className += (element.className) ? element.className : '';
                        return (
                            <ListGroupItem
                                id={element.htmlId}
                                key={element.id}
                                onClick={() => {
                                    if (this.props.setSelectedIndex)
                                        this.props.setSelectedIndex(element.id)
                                }}
                                active={this.props.selectedIndex === element.id}
                                className={className}
                            >
                                {
                                    this.props.editingOn === element.id
                                        ? (
                                            <div className="d-flex">
                                                <div className="custom-input-group">
                                                    {
                                                        this.props.keys.map((key, index) => {
                                                            return (
                                                                key.isEditable
                                                                    ? (
                                                                        <FormGroup key={index}>
                                                                            <Input
                                                                                bsSize="sm"
                                                                                placeholder={this.props.i18n.t(key.placeholder)}
                                                                                value={this.state.editList[index].value || ''}
                                                                                invalid={(this.state.editList[index].invalid) ? true : false}
                                                                                onClick={event => event.stopPropagation()}
                                                                                onChange={event => this.onInlineChangeEvent(event.target.value, index, key.maxCharLimit)}
                                                                            />
                                                                            <FormFeedback>
                                                                                {
                                                                                    this.state.editList[index].invalid === INVALID_TYPE.EMPTY
                                                                                        ? (
                                                                                            this.props.i18n.t('inlineInputErrors.empty')
                                                                                                .replace('<name>', this.props.i18n.t(this.state.editList[index].label))
                                                                                        )
                                                                                        : (
                                                                                            this.state.editList[index].invalid === INVALID_TYPE.OVERFLOW
                                                                                                ? (
                                                                                                    this.props.i18n.t('inlineInputErrors.overflowShort')
                                                                                                        .replace('<name>', this.props.i18n.t(this.state.editList[index].label))
                                                                                                        .replace('<limit>', this.state.editList[index].maxCharLimit)
                                                                                                )
                                                                                                : this.props.i18n.t('inlineInputErrors.error')
                                                                                        )
                                                                                }
                                                                            </FormFeedback>
                                                                        </FormGroup>
                                                                    )
                                                                    : this.renderSpan(`${element.id}-${key.key}-${index}`, key.className, element[key.key], key.tooltip)
                                                            );
                                                        })
                                                    }
                                                </div>
                                                <div className="icons-container">
                                                    <span
                                                        className="cursor-pointer"
                                                        onClick={() => {
                                                            this.props.setEditingOn(-100);
                                                        }}
                                                    >
                                                        <Cancel
                                                            fillColor={
                                                                Styles.primaryColor
                                                            }
                                                        />
                                                    </span>
                                                    <span
                                                        className="cursor-pointer"
                                                        onClick={this.onOkClick}
                                                    >
                                                        <Ok
                                                            fillColor={
                                                                Styles.primaryColor
                                                            }
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                        : (
                                            <div className="d-flex">
                                                <div className="d-flex flex-column w-100 overflow-hidden">
                                                    {
                                                        this.props.keys.map((key, index) => {
                                                            return (
                                                                this.renderSpan(`${element.id}-${key.key}-${index}`, key.className, element[key.key], key.tooltip)
                                                            );
                                                        })
                                                    }
                                                </div>
                                                <div className="icons-container">
                                                    {
                                                        (this.props.onEdited)
                                                            ? <span
                                                                className="cursor-pointer"
                                                                onClick={() => {
                                                                    this.onEditClick(element);
                                                                }}
                                                            >
                                                                <Edit
                                                                    fillColor={
                                                                        (this.props.selectedIndex === element.id)
                                                                            ? Styles.selectionColor
                                                                            : Styles.primaryColor
                                                                    }
                                                                />
                                                            </span>
                                                            : null
                                                    }
                                                    {
                                                        (this.props.onDelete)
                                                            ? <span
                                                                className="cursor-pointer"
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    this.props.setEditingOn(-100);
                                                                    this.showDeleteModal(element);
                                                                }}
                                                            >
                                                                <Delete
                                                                    fillColor={
                                                                        (this.props.selectedIndex === element.id)
                                                                            ? Styles.selectionColor
                                                                            : Styles.primaryColor
                                                                    }
                                                                />
                                                            </span>
                                                            : null
                                                    }
                                                    {
                                                        (element.status === 1)
                                                            ? (
                                                                <span
                                                                    className="checkbox-container cursor-pointer"
                                                                    onClick={() => {
                                                                        this.showDeactivationModal(element);
                                                                    }}
                                                                >
                                                                    <Checked
                                                                        fillColor={Styles.primaryColor}
                                                                    />
                                                                    <div className="color-primary-dark">Active</div>
                                                                </span>
                                                            )
                                                            : null
                                                    }
                                                    {
                                                        (element.status === 0)
                                                            ? (
                                                                <span
                                                                    className="checkbox-container cursor-pointer"
                                                                    onClick={() => {
                                                                        this.showActivationModal(element);
                                                                    }}
                                                                >
                                                                    <Unchecked
                                                                        fillColor={Styles.primaryColor}
                                                                    />
                                                                    <div className="color-primary-dark">Inactive</div>
                                                                </span>
                                                            )
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        )
                                }
                            </ListGroupItem>
                        );
                    })
                }
            </ListGroup>
        );
    }
}

export default withNamespaces()(CustomList)