import React from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    Button,
    UncontrolledTooltip,
} from 'reactstrap';
import AddEditModal from '../../Common/AddEditModal/AddEditModal';
import PopupModal from '../../Common/PopupModal/PopupModal';
import CustomCard from '../../Common/CustomCard/CustomCard';
// hoc
import { withNamespaces } from 'react-i18next';
import { MODAL_SIZE } from '../../../HOC/Modal';
// css
import Styles from './zone.scss';
// assets
import Delete from '../../../Assets/Delete';
import Edit from '../../../Assets/Edit';
import AddZoneModal from './AddZoneModal/AddZoneModal';

class zone extends React.Component {

    static propTypes = {
        list: PropTypes.array,
        metadata: PropTypes.array.isRequired,
        showModal: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
    }

    showAddModal = () => {
        this.props.showModal(
            <AddZoneModal
                i18n={this.props.i18n}
                onCancel={this.props.closeModal}
                onSave={this.props.addZones}
            />
        );
    }

    editZones = (data) => {
        const list = JSON.parse(JSON.stringify(this.props.metadata));
        list.forEach(element => {
            if (data[element.key] !== undefined) {
                element.value = data[element.key];
            }
        });

        this.props.showModal(
            <AddEditModal
                list={list}
                modalHeader={data.zoneName}
                onSaveClick={() => this.props.editZones(list, data.id)}
                onCancelClick={() => {
                    this.props.closeModal();
                    this.props.getZones();
                }}
            />,
            MODAL_SIZE.MD
        );
    }


    deleteCampus = (element) => {
        this.props.showModal(
            <PopupModal
                title='modal.confirmation.confirmDelete'
                message='modal.confirmation.deleteCampusMessage'
                onConfirmationClick={() => this.props.deleteZoneById(element.id)}
                onCancelClick={this.props.getZones}
            />
            , MODAL_SIZE.SM
        );
    }

    render() {
        return (
            <div className="container zone-data">
                <Row className="flex-shrink-0">
                    <Col className="d-flex pt-3">
                        <Button
                            className="margin-inline-start-auto"
                            onClick={() => this.showAddModal()}
                        >
                            {this.props.i18n.t('zones.addzone')}
                        </Button>
                    </Col>
                </Row>
                {
                    (this.props.list)
                        ? (
                            (this.props.list.length === 0)
                                ? (
                                    <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                                        {this.props.i18n.t('common.noDataAvailable')}
                                    </div>
                                )
                                : (
                                    <Row className="campus-list">
                                        {
                                            this.props.list.map((element, index) => {
                                                return (
                                                    <Col
                                                        key={index}
                                                        md={3}
                                                        className="my-3"
                                                    >
                                                        <CustomCard
                                                            body={
                                                                <div className="py-3 px-1">
                                                                    {
                                                                        this.props.metadata.map(metadata => {
                                                                            if (metadata.isVisible !== false) {
                                                                                return (
                                                                                    <Col key={metadata.key} md={metadata.md}>
                                                                                        <div className={`d-flex ${metadata.className}`}>
                                                                                            {
                                                                                                (metadata.icon)
                                                                                                    ? (
                                                                                                        <div className='label-div'>
                                                                                                            <span className="mr-1 width-div">
                                                                                                                <metadata.icon
                                                                                                                    fillColor={Styles.textLightColor}
                                                                                                                />

                                                                                                            </span>
                                                                                                            <span className='metaLabel'>
                                                                                                                {this.props.i18n.t(metadata.label)}:</span>
                                                                                                        </div>
                                                                                                    )
                                                                                                    : null
                                                                                            }
                                                                                            <span className="d-block text-truncate">
                                                                                                <span id={`UncontrolledTooltip-${index}-${metadata.key}`} className="d-block text-truncate">{element[metadata.key]}
                                                                                                 {metadata.key==='range'?" meters":null}
                                                                                                </span>
                                                                                                
                                                                                                {
                                                                                                    (metadata.tooltip)
                                                                                                        ? (
                                                                                                            <UncontrolledTooltip className={metadata.className} target={`UncontrolledTooltip-${index}-${metadata.key}`}>
                                                                                                                {element[metadata.key]}
                                                                                                            </UncontrolledTooltip>
                                                                                                        )
                                                                                                        : null
                                                                                                }
                                                                                            </span>
                                                                                        </div>
                                                                                    </Col>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return null;
                                                                            }
                                                                        })
                                                                    }
                                                                    <Col className='btn-div'>
                                                                        <Button
                                                                            className="btn-icon"
                                                                            onClick={() => this.editZones(element)}
                                                                        >
                                                                            <Edit
                                                                                fillColor={Styles.selectionColor}
                                                                            />
                                                                        </Button>
                                                                        <Button
                                                                            className="btn-icon"
                                                                            onClick={() => this.deleteCampus(element)}
                                                                        >
                                                                            <Delete
                                                                                fillColor={Styles.selectionColor}
                                                                            />
                                                                        </Button>
                                                                    </Col>
                                                                </div>
                                                            }
                                                        />
                                                    </Col>
                                                );
                                            })
                                        }
                                    </Row>
                                )
                        )
                        : null
                }
            </div>
        );
    }
}

export default withNamespaces()(zone);