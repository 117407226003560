import React from 'react';
import PropTypes from 'prop-types';

export default class Ok extends React.Component {

    static propTypes = {
        fillColor: PropTypes.string.isRequired,
    }

    render() {
        return (
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                <g id="ic_right" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Group-21">
                        <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                        <path d="M20.8321378,6.98861554 L19.9905274,6.16483659 C19.8783834,6.0548301 19.7491997,6 19.6031531,6 C19.4574603,6 19.3283355,6.0548301 19.2161916,6.16483659 L8.99939074,16.0503715 L4.78428013,10.9842868 C4.6720772,10.8742804 4.54295244,10.8194503 4.39708274,10.8194503 C4.25115407,10.8194503 4.12202931,10.8742804 4.00988534,10.9842868 L3.16839284,11.8081235 C3.05601302,11.9178991 3,12.0442969 3,12.1871438 C3,12.3298752 3.05618991,12.456273 3.16839284,12.5660486 L8.61219334,18.8352788 C8.72433731,18.9451122 8.85352103,19 8.99939074,19 C9.14526044,19 9.27420832,18.9451122 9.38629333,18.8352788 L20.8321378,7.74677152 C20.9442818,7.63693817 21,7.51054037 21,7.36786668 C21,7.22501984 20.9442818,7.09862203 20.8321378,6.98861554 Z" id="Path-Copy" fill={this.props.fillColor}></path>
                    </g>
                </g>
            </svg>
        );
    }

}

