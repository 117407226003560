import React from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    Input,
} from 'reactstrap';
import { Button } from 'reactstrap';
import PopupModal from '../Common/PopupModal/PopupModal';
 //css
import Styles from './BannerSettings.scss';
 //hoc
import { withNamespaces } from 'react-i18next';
 //assets
import Delete from '../../Assets/Delete';
import Cancel from '../../Assets/Cancel';

 //constants
import { PARSING_KEYS } from '../../Constants/AppConstants';
import { MODAL_SIZE } from '../../HOC/Modal';
import { RxDragHandleHorizontal } from "react-icons/rx";

class Banners extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editingOn: '',
            bannersurl: null,
            imageUrlInput:'',
            isDragAndDropEnabled: false,
        }
    }

    static propTypes = {
        showModal: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
        bannersurl: PropTypes.array,
        getBannersUrl: PropTypes.func.isRequired,
        getBannersUrlById: PropTypes.func.isRequired,
        putBannersUrlById: PropTypes.func.isRequired,
        deleteBannerUrlById: PropTypes.func.isRequired,
    }

    static getDerivedStateFromProps(props, state) {
        return ({
            editingOn: state.editingOn,
            bannersurl: (state.editingOn) ? state.bannersurl : JSON.parse(JSON.stringify(props.bannersurl)),
        });
    }


    showBannersUrlErrorModal = (message, id, oldValue) => {
        const { bannersurl } = this.state;
        const { SETTINGS } = PARSING_KEYS;
        const index = bannersurl.findIndex(element => element[SETTINGS.ID] === id)
        bannersurl[index][SETTINGS.VALUE] = oldValue;
        this.setState({
           bannersurl,
        });
        this.props.showModal(
            <PopupModal
                isError
                message={message}
                onOkClick={this.props.closeModal}
            />
            , MODAL_SIZE.SM
        )
    }

    onOkClick = (data) => {
        const { SETTINGS } = PARSING_KEYS;
        const { imageUrlInput } = this.state;
    
        if (imageUrlInput) {
            let requestBody = this.props.bannersurl.find(element => element[SETTINGS.ID] === data[SETTINGS.ID]);
            if (requestBody.COMPARE_WITH) {
                const compareValue = parseInt(this.props.bannersurl.find(element => element[SETTINGS.ID] === requestBody.COMPARE_WITH)[SETTINGS.VALUE]);
                const oldValue = requestBody[SETTINGS.VALUE];
                const newValue = imageUrlInput;
    
                if (!requestBody.COMPARE_FUNCTION(newValue, compareValue)) {
                    this.showBannersUrlErrorModal(`settings.${requestBody[SETTINGS.ID]}.errorMessage`, requestBody[SETTINGS.ID], oldValue);
                    return;
                }
            }
            if (requestBody[SETTINGS.VALUE]) {
                requestBody[SETTINGS.VALUE] += `,${imageUrlInput}`;
            } else {
                requestBody[SETTINGS.VALUE] = imageUrlInput;
            }
    
            this.props.putBannersUrlById(requestBody);
    
            this.setState({ imageUrlInput: '' });
            this.setState({ editingOn: '' });
        } else {
            this.setState({
                editingOn: '',
                imageUrlInput: '',
                bannersurl: this.props.bannersurl,
            });
        }
    }
    
    

    deleteUrl = (element, urlToDelete) => {
        const { SETTINGS } = PARSING_KEYS;
        const bannerId = element[SETTINGS.ID];
        this.props.showModal(
            <PopupModal
                title='modal.confirmation.confirmDelete'
                message='modal.confirmation.deleteCampusMessage'
                onConfirmationClick={() =>  this.props.deleteBannerUrlById(bannerId, urlToDelete)}
                onCancelClick={this.props.getBannersUrl}
            />
            , MODAL_SIZE.SM
        );
    }
    

    validateBannersUrl = (index, value) => {
        let { bannersurl } = this.state;
        bannersurl = JSON.parse(JSON.stringify(bannersurl))
        const regex = new RegExp(bannersurl[index].REGEX);
        if (regex.test(value)) {
            bannersurl[index][PARSING_KEYS.SETTINGS.VALUE] = value;
            this.setState({ bannersurl });
        }
    }
    
    handleImageUrlInputChange = (event) => {
        this.setState({
            imageUrlInput: event.target.value,
        });
    };
    
    onCancelClick = () => {
        this.setState({
            imageUrlInput: '',
            editingOn: '',
        });
    }
       
    renderimage= (list, startingIndex = 0) => {
        const { SETTINGS } = PARSING_KEYS;
        return (
            <div className="other-settings-panel">
                {
                    (list.length === 0)
                        ? (
                            <div className="text-center">
                                {this.props.i18n.t('common.noDataAvailable')}
                            </div>
                        )
                        : (
                            list.map((element, index) => {
                                return (
                                    <div  key={element[SETTINGS.ID]} className="settings-element">
                                        <div>
                                            <div className='text-field'>
                                                <Input
                                                        type="text"
                                                        value={this.state.imageUrlInput}
                                                        onChange={this.handleImageUrlInputChange}
                                                        placeholder="Enter Image URL"
                                                        className="input-field"
                                                />
                                                <div className='add-button'>
                                                    <Button
                                                        className="margin-inline-start-auto"
                                                        onClick={() => this.onOkClick(element)}
                                                        disabled={!this.state.imageUrlInput}
                                                    >
                                                        ADD
                                                    </Button>
                                                    <Button
                                                        color='light'
                                                        className="btn-cancel"
                                                        onClick={() => this.onCancelClick()}
                                                    > <Cancel
                                                        fillColor={Styles.primaryColor}/>
                                                    </Button>                      
                                                </div>
                                            </div>
                                            <div className="input-label">
                                                <div className="hint-text">
                                                    {this.props.i18n.t(`settings.${element[SETTINGS.ID]}.hint-text`)}
                                                </div>
                                            </div> 
                                            {this.state.imageUrlInput && (
                                                <div className='outputContainer-display' style={{justifyContent:'center', marginTop:'px'}}>
                                                    <div className="image-box">
                                                        <img
                                                                className='image-display'
                                                                src={this.state.imageUrlInput}
                                                                alt="No Preview Available"
                                                        />
                                                    </div>
                                                </div>
                                            )} 
                                           
                                        </div>
                                    </div>
                                )}
                            )
                        )
                }
            </div>
        );

    }

    onDragStart = (event, configName, urlIndex) => {
        const { isDragAndDropEnabled } = this.state;

        if (!isDragAndDropEnabled) {
          event.preventDefault(); // Cancel the drag operation when disabled
          return;
        }
      
        event.dataTransfer.setData('configName', configName);
        event.dataTransfer.setData('urlIndex', urlIndex);
        event.target.classList.add('selected');
        console.log("Drag Started....");
    };

    onDragOver = (event) => {
        event.preventDefault();
    };

    onDrop = (event, configName, targetUrlIndex) => {
        const sourceConfigName = event.dataTransfer.getData('configName');
        const sourceUrlIndex = event.dataTransfer.getData('urlIndex');

        const { bannersurl } = this.state;
        const { SETTINGS } = PARSING_KEYS;
        const banners = this.props.bannersurl.slice();
        const sourceItem = banners.find((element) => element[SETTINGS.ID] === sourceConfigName);
        const targetItem = banners.find((element) => element[SETTINGS.ID] === configName);

        if (sourceItem && targetItem) {
            const draggedUrl = sourceItem[SETTINGS.VALUE].splice(sourceUrlIndex, 1)[0];
            targetItem[SETTINGS.VALUE].splice(targetUrlIndex, 0, draggedUrl);
            const combinedUrls = targetItem[SETTINGS.VALUE].join(',');
        
            const requestBody = {
                configName: 'KU_EVENTS_IMAGE_URLS',
                value: combinedUrls,
            };
            this.setState({ bannersurl }, () => {
                this.props.putBannersUrlById(requestBody); 
                console.log("Drag Ended....");
            });
        }
        event.target.classList.remove('selected');
    };
    
    // Toggle the drag-and-drop state
    toggleDragAndDrop = () => {
        this.setState((prevState) => ({
            isDragAndDropEnabled: !prevState.isDragAndDropEnabled,
        }));
    };
   
    renderBannersUrl = (list, startingIndex = 0) => {
        const { SETTINGS } = PARSING_KEYS;
        const { isDragAndDropEnabled } = this.state;
  
        return (
            <div className="other-settings-panel">
                <div className="button-container" style={{textAlign:'right', marginRight: '18px'}}>
                    <Button onClick={this.toggleDragAndDrop}>
                        {isDragAndDropEnabled ? 'Disable Sorting' : 'Enable Sorting'}
                    </Button>
                </div>
                {list.length === 0 ? (
                    <div className="text-center">
                        {this.props.i18n.t('common.noDataAvailable')}
                    </div>
                ) : (
                        list.map((element, index) => {
                        return (
                            <div key={element[SETTINGS.ID]} className="settings-element">
                                <div className="display-container">
                                    {Array.isArray(element[SETTINGS.VALUE]) ? (
                                        element[SETTINGS.VALUE].map((url, urlIndex) => (
                                        <div
                                            key={url}
                                            className={`outputContainer-display${isDragAndDropEnabled ? ' draggable' : ' disabled'}`}
                                            draggable={isDragAndDropEnabled}
                                            onDragStart={(event) => this.onDragStart(event, element[SETTINGS.ID], urlIndex)}
                                            onDragOver={(event) => this.onDragOver(event)}
                                            onDrop={(event) => this.onDrop(event, element[SETTINGS.ID], urlIndex)}
                                        >
                                             {isDragAndDropEnabled ? (
                                                  
                                                   <><div className="id">{urlIndex + 1}</div>
                                                     <div className="image-box">
                                                      <img src={url} alt={`${urlIndex}`} />
                                                    </div>
                                                    <div className="url">{url}</div></>
                                                 
                                                ) : (
                                                  
                                                    <><div className="image-box">
                                                        <img src={url} alt={`${urlIndex}`} />
                                                        </div>
                                                        <div className="url">{url}</div></>
                                               
                                            )}
                                            <Button
                                                className={isDragAndDropEnabled ? "hamburger-button" : "delete-button"}
                                                onClick={isDragAndDropEnabled ? null : () => this.deleteUrl(element, url)}
                                            >
                                                {isDragAndDropEnabled ? (
                                                    <RxDragHandleHorizontal size="30px" style={{}}/>
                                                ) : (
                                                    <Delete fillColor={Styles.selectionColor} />
                                            )}
                                            </Button>
                                        </div>
                                    ))
                                    ) : (
                                            <div>
                                                <img src={element[SETTINGS.VALUE]} alt={`0`} style={{ width: "300px", height: "200px" }} />
                                                {element[SETTINGS.VALUE]}
                                            </div>
                                        )}
                                </div>
                            </div>
                        );
                    })
                )}
            </div>
        );
    }
  

    render() {
        return (
            <div className="container banner-settings">
                <Row className="my-3">
                    <Col sm={12}>
                    {this.renderimage(this.state.bannersurl)}
                    </Col>
                </Row>
                <Row className="my-3">
                   <Col sm={12}>
                        {this.renderBannersUrl(this.state.bannersurl)}
                    </Col> 
                </Row>
            </div >
        );
    }
}

export default withNamespaces()(Banners);

