import React from 'react';
import {
    ModalHeader,
    ModalBody,
    Button,
    Row,
    Col,
    FormGroup,
    FormFeedback,
    Label,
    Input,
} from 'reactstrap';
import DisplayInputs from '../../Common/DisplayInputs/DisplayInputs';
// css
import Styles from './AddCampusModal.scss';
// assete
import Remove from '../../../Assets/Remove';
import { REGEX, CAMPUS_METADATA } from '../../../Constants/AppConstants';
import { withNamespaces } from 'react-i18next';

class AddCampusModal extends React.Component {

    constructor(props) {
        super(props);

        this.list = JSON.parse(JSON.stringify(CAMPUS_METADATA));
        /* [
            {
                label: this.props.i18n.t('modal.addCampus.nameEN'),
                md: "6",
                isEditable: true,
                value: "",
                key: PARSING_KEYS.CAMPUS.NAME_EN,
                maxCharLimit: 100,
                placeholder: this.props.i18n.t('placeholder.campusNameEn'),
            },
            {
                label: this.props.i18n.t('modal.addCampus.nameAR'),
                md: "6",
                isEditable: true,
                value: "",
                key: PARSING_KEYS.CAMPUS.NAME_AR,
                maxCharLimit: 100,
                placeholder: this.props.i18n.t('placeholder.campusNameAr'),
            },
            {
                label: this.props.i18n.t('modal.addCampus.email'),
                md: "6",
                isEditable: true,
                value: "",
                key: PARSING_KEYS.CAMPUS.EMAIL,
                maxCharLimit: 50,
                placeholder: this.props.i18n.t('placeholder.email'),
                regex: REGEX.EMAIL,
            },
            {
                label: this.props.i18n.t('modal.addCampus.whatsappNo'),
                md: "6",
                isEditable: true,
                value: "",
                key: PARSING_KEYS.CAMPUS.WHATSAPP_NO,
                maxCharLimit: 20,
                placeholder: this.props.i18n.t('placeholder.number'),
                regex: REGEX.WHATSAPP_NO,
            }
        ] */
        this.list.forEach(element => {
            element.md = 6;
        })

        this.key = 0;
        this.state = {
            buildings: [],
            defaultList: [
                {
                    checkEmpty: false,
                },
                {
                    checkEmpty: false,
                },
                {
                    checkEmpty: false,
                },
                {
                    checkEmpty: false,
                    regex: REGEX.EMAIL,
                },
                {
                    checkEmpty: false,
                    regex: REGEX.MOBILE_NO,
                },
            ],
            newBuildingNameEN: "",
            newBuildingNameAR: "",
            invalidBuildingNameEN: '',
            invalidBuildingNameAR: '',
        }
    }

    addNewBuilding = () => {
        const { buildings, newBuildingNameEN, newBuildingNameAR } = this.state;
        let error = false;
        if (newBuildingNameEN.trim() === "") {
            error = true;
            this.setState({
                invalidBuildingNameEN: 'errorMessages.buildingNameRequiredEN',
            })
        }
        if (newBuildingNameAR.trim() === "") {
            error = true;
            this.setState({
                invalidBuildingNameAR: 'errorMessages.buildingNameRequiredAR',
            })
        }
        if (error) {
            return;
        }

        newBuildingNameEN.replace(REGEX.INBETWEEN_SPACES, ' ');
        newBuildingNameAR.replace(REGEX.INBETWEEN_SPACES, ' ');

        error = false;
        let invalidBuildingNameEN = '';
        let invalidBuildingNameAR = '';
        buildings.forEach(element => {
            if (element.bldgNameEn.toUpperCase() === newBuildingNameEN.toUpperCase()) {
                error = true;
                invalidBuildingNameEN = 'errorMessages.duplicateBuilding';
            }
            if (element.bldgNameAr.toUpperCase() === newBuildingNameAR.toUpperCase()) {
                error = true;
                invalidBuildingNameAR = 'errorMessages.duplicateBuilding';
            }
            if (error) {
                this.setState({
                    invalidBuildingNameEN,
                    invalidBuildingNameAR,
                })
                return;
            }
        })

        if (!error) {
            buildings.push({
                bldgNameEn: newBuildingNameEN.trim(),
                bldgNameAr: newBuildingNameAR.trim(),
            });

            this.setState({
                buildings,
                atLeastOneBuilding: false,
                newBuildingNameEN: "",
                newBuildingNameAR: "",
            });
        }
    }

    removeBuilding = (index) => {
        const { buildings } = this.state;
        buildings.splice(index, 1);
        this.setState({ buildings });
    }

    onSaveClick = () => {
        if (this.state.buildings.length === 0) {
            this.setState({
                atLeastOneBuilding: true,
            })
        }
        let isInvalid = false;
        const { defaultList } = this.state;
        this.list.forEach((element, index) => {
            defaultList[index].checkEmpty = true;
            if (element.isEditable && element.invalid) {
                isInvalid = true;
            }
            element.value = element.value.replace(REGEX.INBETWEEN_SPACES, ' ');
        })
        const atLeastOneBuilding = this.state.buildings.length === 0;
        if (isInvalid || atLeastOneBuilding) {
            this.setState({
                defaultList,
                atLeastOneBuilding: atLeastOneBuilding,
            });
        }
        else {
            this.list.forEach(element => {
                element.key = element.value;
            })
            this.props.onSave(this.list, this.state.buildings)
        }
    }

    render() {
        return (
            <div className="add-campus-modal">
                <ModalHeader>
                    {this.props.i18n.t('modal.addCampus.addCampus')}
                </ModalHeader>
                <ModalBody>
                    <div className="tile">
                        <DisplayInputs
                            list={this.list}
                            defaultList={this.state.defaultList}
                        />
                    </div>
                    <div className="separator" />
                    {
                        this.state.buildings.length !== 0
                            ? (
                                <div>
                                    <div className="tile">
                                        <Row>
                                            <Col>
                                                {this.props.i18n.t('modal.addCampus.recentlyAddedBuildings')}
                                            </Col>
                                        </Row>
                                        <Row id="buildingsList" className="buildings-list">
                                            {
                                                this.state.buildings.map((element, index) => {
                                                    return (
                                                        <Col
                                                            key={++this.key}
                                                            className="px-3"
                                                            md={4}
                                                        >
                                                            <div className="small-card">
                                                                <div className="text-truncate">
                                                                    {element.bldgNameEn}
                                                                </div>
                                                                <div className="text-truncate">
                                                                    {element.bldgNameAr}
                                                                </div>
                                                                <div
                                                                    md={2}
                                                                    className="remove-icon"
                                                                    onClick={() => this.removeBuilding(index)}
                                                                >
                                                                    <Remove
                                                                        fillColor={Styles.primaryColor}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    );
                                                })
                                            }
                                        </Row>
                                    </div>
                                    <div className="separator" />
                                </div>
                            )
                            : null
                    }
                    <div className="tile">
                        <Row>
                            <Col className="d-flex">
                                <span className="flex-shrink-0 mr-3">
                                    {this.props.i18n.t('modal.addCampus.addBuildings')}
                                </span>
                                {
                                    (this.state.atLeastOneBuilding)
                                        ? (
                                            <div className="invalid-text d-flex">
                                                {this.props.i18n.t('errorMessages.atLeastOneBuilding')}
                                            </div>
                                        )
                                        : null
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col md={10}>
                                <Row>
                                    <Col>
                                        <FormGroup className="mb-2">
                                            <Label className="m-0">{this.props.i18n.t('modal.addCampus.nameEN')}</Label>
                                            <Input
                                                bsSize="sm"
                                                placeholder={this.props.i18n.t('placeholder.buildingNameEn')}
                                                value={this.state.newBuildingNameEN}
                                                onChange={
                                                    event => {
                                                        if (event.target.value.length <= 100) {
                                                            this.setState({
                                                                newBuildingNameEN: event.target.value,
                                                                invalidBuildingNameEN: '',
                                                            });
                                                        }
                                                    }
                                                }
                                                invalid={this.state.invalidBuildingNameEN !== ''}
                                            />
                                            <FormFeedback>
                                                {this.props.i18n.t(this.state.invalidBuildingNameEN)}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label className="m-0">{this.props.i18n.t('modal.addCampus.nameAR')}</Label>
                                            <Input
                                                bsSize="sm"
                                                placeholder={this.props.i18n.t('placeholder.buildingNameAr')}
                                                value={this.state.newBuildingNameAR}
                                                onChange={
                                                    event => {
                                                        if (event.target.value.length <= 100) {
                                                            this.setState({
                                                                newBuildingNameAR: event.target.value,
                                                                invalidBuildingNameAR: '',
                                                            });
                                                        }
                                                    }
                                                }
                                                invalid={this.state.invalidBuildingNameAR !== ''}
                                            />
                                            <FormFeedback>
                                                {this.props.i18n.t(this.state.invalidBuildingNameAR)}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={2} className="d-flex align-items-center justify-content-center">
                                <Button
                                    onClick={this.addNewBuilding}
                                >
                                    {this.props.i18n.t('modal.buttons.add')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <div className="separator" />
                    <div className="container py-2">
                        <Row>
                            <Col className="d-flex">
                                <Button
                                    className="margin-inline-start-auto"
                                    outline
                                    onClick={this.props.onCancel}
                                >
                                    {this.props.i18n.t('modal.buttons.cancel')}
                                </Button>
                                <Button
                                    className="mx-3"
                                    onClick={this.onSaveClick}
                                >
                                    {this.props.i18n.t('modal.buttons.save')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </div >
        );
    }
}

export default withNamespaces()(AddCampusModal);