import React from 'react';
import PropTypes from 'prop-types';
import {
    ModalHeader,
    ModalBody,
    Button,
} from 'reactstrap';
import DisplayInputs from '../DisplayInputs/DisplayInputs';
// hoc
import { withNamespaces } from 'react-i18next';
// css
import './AddEditModal.scss';
// constants
import { REGEX } from '../../../Constants/AppConstants';

class AddEditModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            list: [],
            defaultList: [],
        }
    }

    static propTypes = {
        list: PropTypes.array.isRequired,
        modalHeader: PropTypes.string.isRequired,
        onSaveClick: PropTypes.func.isRequired,
        onCancelClick: PropTypes.func.isRequired,
        clear: PropTypes.bool,
        isEmptyAllowed: PropTypes.bool,
    }

    static defaultProps = {
        clear: false,
        isEmptyAllowed: true,
    }

    static mapStateToProps(state, props) {
        return AddEditModal.setList(props);
    }

    static setList(props) {
        const defaultList = JSON.parse(JSON.stringify(props.list));
        defaultList.forEach(element => {
            if (element.value) {
                element.checkEmpty = true;
            }
        })
        return {
            list: props.list,
            defaultList,
        }
    }

    clear = () => {
        const { list } = this.state;
        list.forEach(element => element.value = '');
        this.setState({ defaultList: [] });
    }

    onSaveClick = () => {
        const { list, defaultList } = this.state;
        let isError = false;
        list.forEach((element, index) => {
            if (element.isEditable && (element.invalid || element.value === '')) {
                defaultList[index].checkEmpty = true;
                isError = true;
            }
        })
        if (isError) {
            this.setState({ defaultList });
            return;
        }
        let dataChanged = false;
        list.forEach((element, index) => {
            Object.keys(element).forEach(key => {
                if (key !== 'invalid' && element[key] !== defaultList[index][key])
                    dataChanged = true;
            })
            if (typeof element.value === 'string') {
                element.value = element.value.replace(REGEX.INBETWEEN_SPACES, ' ').trim();
            }
        })
        if (dataChanged) {
            this.props.onSaveClick();
        }
        else {
            this.props.onCancelClick();
        }
    }

    componentDidMount() {
        this.setState(AddEditModal.setList(this.props));
    }

    render() {
        return (
            <div className="add-edit-link-modal" >
                <ModalHeader>{this.props.i18n.t(this.props.modalHeader)}</ModalHeader>
                <ModalBody>
                    <div className="container-fluid p-0">
                        <DisplayInputs
                            list={this.state.list}
                            defaultList={this.state.defaultList}
                            
                        />
                        {
                            this.props.clear
                                ? (
                                    <div
                                        className="clear-text"
                                        onClick={this.clear}
                                    >
                                        {this.props.i18n.t('common.clear')}
                                    </div>
                                )
                                : null
                        }
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                        <Button
                            className="margin-inline-start-auto mr-2"
                            outline
                            onClick={this.props.onCancelClick}
                        >
                            {this.props.i18n.t('modal.buttons.cancel')}
                        </Button>
                        <Button
                            onClick={this.onSaveClick}
                        >
                            {this.props.i18n.t('modal.buttons.save')}
                        </Button>
                    </div>
                </ModalBody>
            </div>
        );
    }
}

export default withNamespaces()(AddEditModal);