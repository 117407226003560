import React from 'react';
import PropTypes from 'prop-types';

export default class Cancel extends React.Component {

    static propTypes = {
        fillColor: PropTypes.string.isRequired,
    }

    render() {
        return (
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                <g id="ic_cancel" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Group-20">
                        <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                        <path d="M18.8566596,5.8205509 L18.1554807,5.13681437 C18.0620491,5.04550898 17.9544211,5 17.8327439,5 C17.7113614,5 17.6037825,5.04550898 17.5103509,5.13681437 L11.9983298,10.5118084 L6.48655439,5.13695808 C6.39307368,5.04565269 6.28549474,5.00014371 6.16396491,5.00014371 C6.04238596,5.00014371 5.93480702,5.04565269 5.84137544,5.13695808 L5.14029474,5.82074251 C5.04666667,5.91185629 5,6.01676647 5,6.13532934 C5,6.25379641 5.04681404,6.35870659 5.14029474,6.44982036 L11.6757404,12.8232814 C11.7691719,12.9144431 11.8768,12.96 11.9983298,12.96 C12.1198596,12.96 12.2272912,12.9144431 12.3206737,12.8232814 L18.8566596,6.44982036 C18.9500912,6.35865868 18.9965123,6.2537485 18.9965123,6.13532934 C18.9965123,6.01676647 18.9500912,5.91185629 18.8566596,5.8205509 Z" id="Path" fill={this.props.fillColor}></path>
                        <path d="M18.8566596,11.8205509 L18.1554807,11.1368144 C18.0620491,11.045509 17.9544211,11 17.8327439,11 C17.7113614,11 17.6037825,11.045509 17.5103509,11.1368144 L11.9983298,16.5118084 L6.48655439,11.1369581 C6.39307368,11.0456527 6.28549474,11.0001437 6.16396491,11.0001437 C6.04238596,11.0001437 5.93480702,11.0456527 5.84137544,11.1369581 L5.14029474,11.8207425 C5.04666667,11.9118563 5,12.0167665 5,12.1353293 C5,12.2537964 5.04681404,12.3587066 5.14029474,12.4498204 L11.6757404,18.8232814 C11.7691719,18.9144431 11.8768,18.96 11.9983298,18.96 C12.1198596,18.96 12.2272912,18.9144431 12.3206737,18.8232814 L18.8566596,12.4498204 C18.9500912,12.3586587 18.9965123,12.2537485 18.9965123,12.1353293 C18.9965123,12.0167665 18.9500912,11.9118563 18.8566596,11.8205509 Z" id="Path" fill={this.props.fillColor} transform="translate(11.998256, 14.980000) scale(1, -1) translate(-11.998256, -14.980000) "></path>
                    </g>
                </g>
            </svg>
        );
    }

}
