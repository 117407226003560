import React from 'react';
import PropTypes from 'prop-types';

export default class Unchecked extends React.Component {

    static propTypes = {
        fillColor: PropTypes.string.isRequired,
    }

    render() {
        return (
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                <g id="ic_uncheck" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Group-5-Copy-2" transform="translate(4.000000, 3.000000)" fill="#FFFFFF" stroke={this.props.fillColor}>
                        <g id="Group-3">
                            <rect id="Rectangle" x="0.5" y="0.5" width="16" height="16" rx="4"></rect>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }

}


