import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './EmployeeList.scss';
import { MODAL_SIZE } from '../../../../HOC/Modal';
import PopupModal from '../../../Common/PopupModal/PopupModal';
import { withNamespaces } from 'react-i18next';
import { APPROVAL_HEAD } from '../../../../Constants/AppConstants';
import { formatUtcToLocal } from '../../../Common/commonutility';

class EmployeeCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showViewModal: false, 
            remark: '', 
            error:""
        };
    }

    static propTypes = {
        employee: PropTypes.object.isRequired,
        showModal: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
    };

   
    ApproveEmployee = (data, modalType, event) => {
        event.stopPropagation(); // Prevent the event from bubbling up to the card
        this.setState({ remark: '', error: '' }, () => {
            this.showApprovalModal(data, modalType);
        });
    };

    showApprovalModal = (data, modalType) => {
        const handleRemarkChange = (e) => {
            this.setState({ remark: e.target.value }, () => {
                this.showApprovalModal(data, modalType); // Re-render modal with updated state
            });
        };

        const handleApproval = () => {
            // if (!this.state.remark.trim()) {
            //     this.setState({ error: this.props.i18n.t('errorMessages.remarkRequired') }, () => {
            //         this.showApprovalModal(data, modalType); 
            //     });
            // } else {
            //     this.setState({ error: '' }); 
            //     this.props.editStatus(data.id, modalType, this.state.remark);
                
            // }
            this.props.editStatus(data.id, modalType, this.state.remark);
        };

       
        this.props.showModal(
            <PopupModal
                title={modalType === 'Approved' ? 'Approval Remark' : 'Denial Remark'}
                body={(
                    <>
                        <textarea
                            className="remark-input"
                            placeholder={this.props.i18n.t('approvals.enterRemarkplace')}
                            rows="3"
                            value={this.state.remark}  
                            onChange={handleRemarkChange}  
                        />
                        {/* {this.state.error && <span className="error-text">{this.state.error}</span>} */}
                    </>
                )}
                color={modalType === 'Approved' ? '#28a745' : '#dc3545'} 
                bodyClasses="popup-body-extended"
                onConfirmationClick={handleApproval}  
                onCancelClick={this.props.closeModal}  
                confirmButtonText={modalType === 'Approved' ? 'Approve' : 'Reject'}
                cancelButtonText="Cancel"
            />,
            MODAL_SIZE.SM
        );
        
    };
   

  
    render() {
        const { employee,toggleModal } = this.props;

        return (
            <>
                <div className="employee-card" onClick={()=>toggleModal(employee.empId)}>
                    <div className="employee-card-body">
                        {/* <div className="employee-image-section">
                            <img src={`data:image/jpeg;base64,${employee.empImage}`} alt="Employee" className="employee-image" />
                        </div> */}
                        <div className="employee-details">
                            <h4 className="employee-name">{employee.empName}</h4>
                            <div className="employee-id">{this.props.i18n.t('approvals.id')}
                                <span> {employee.empId}</span>

                            </div>
                            <div className="employee-department">
                                {this.props.i18n.t('approvals.requested')}
                                <span> {formatUtcToLocal(employee.createdAt, 'MMM dd, yyyy HH:mm:ss')}</span>
                            </div>
                        </div>
                        {employee.approvalStatus===APPROVAL_HEAD[2].activeTab  ?  (
                            <div className="employee-updated-info">
                                <div className="updated-by">
                                    <strong>
                                    {this.props.i18n.t('approvals.rejectedby')}
                                    </strong>
                                    <span className='value'>{employee.approverName}</span>
                                    <div className="tooltip">{employee.approverName}</div>
                                </div>
                                <div className="updated-at">
                                    <strong>
                                    {this.props.i18n.t('approvals.rejectedat')}
                                    </strong>
                                    <span className='value'>{formatUtcToLocal(employee.updatedAt, 'MMM dd, yyyy HH:mm:ss')}</span>
                                    <div className="tooltip">{formatUtcToLocal(employee.updatedAt, 'MMM dd, yyyy HH:mm:ss')}</div>
                                </div>
                                {employee.approvalRemarks && (
                                <div className="remark-div">
                                    <strong>{this.props.i18n.t('approvals.remark')}</strong>
                                    <span className='value remark-text'>{employee.approvalRemarks}</span>
                                    <div className="tooltip">{employee.approvalRemarks}</div>
                                </div>
                                )}
                            </div>
                            ):(
                                <div className="employee-actions">
                               {employee.approvalStatus===APPROVAL_HEAD[0].activeTab  ?
                                <button className="btn-approval approve-btn" onClick={(e) => this.ApproveEmployee(employee, 'Approved', e)}>
                                    {this.props.i18n.t('modal.buttons.approve')}
                                </button>:null}
                          
                                <button className="btn-approval deny-btn" onClick={(e) => this.ApproveEmployee(employee, 'Rejected', e)}>
                                    {this.props.i18n.t('modal.buttons.reject')}
                                </button>
            
                        </div>
                            )
                        }
                    </div>
                </div>

                
            </>
        );
    }
}

export default withNamespaces()(EmployeeCard);
