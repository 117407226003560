import React from 'react';
import { Spinner } from 'reactstrap';
// hoc
import { withModal } from '../../../HOC/Modal';
import { axiosInstance } from '../../../HOC/Communication';
// constants
import { URLS } from '../../../HOC/Communication/ApiUrls';
import { ZONE_METADATA } from '../../../Constants/AppConstants';
import Zone from './zone';

class ZoneContainer extends React.Component {
    constructor(props) {
        super(props);

        this.init();

        this.state = {
            list: null,
        }
    }

    init = () => {
        this.metadata = ZONE_METADATA ;
    }

    /**
     * get campus data after mount
     */
    componentDidMount() {
        this.axios = axiosInstance;
        this.getZones();
        this.props.handelBackButton();
    }

    getZones = () => {
        this.props.showModal(
            <Spinner />
        );

        this.axios.get(URLS.GET_ZONES)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    const list = res.data.data;
                    console.log('✌️list --->', list);
                    this.setState({ list });
                }
                else {
                    this.setState({ list: [] });
                }
            })
    }

    editZone = (list, id) => {
        this.props.showModal(
            <Spinner />
        );
        const data = {};
        list.forEach(element => {
            data[element.key] = element.value;
        })
        this.axios.put(URLS.GET_ZONES + id,data)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    this.getZones();
                }
            });
    }

    addZones = (DataList) => {
        this.props.showModal(
            <Spinner />
        );
        const zoneRequest = {};
        DataList.forEach(element => {
            zoneRequest[element.key] = element.value;
        })
        this.axios.post(URLS.GET_ZONES,zoneRequest)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    this.getZones();
                }
            })
    }

    deleteZoneById = (id) => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.delete(URLS.GET_ZONES + id)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    this.getZones();
                }
            })
    }


    render() {
        return (
            <Zone
                list={this.state.list}
                metadata={this.metadata}
                showModal={this.props.showModal}
                closeModal={this.props.closeModal}
                getZones={this.getZones}
                editZones={this.editZone}
                addZones={this.addZones}
                deleteZoneById={this.deleteZoneById}
               
            />
        )
    }
}

export default withModal(ZoneContainer);