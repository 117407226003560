import React from 'react';
import FacilityCampus from './FacilityCampus';
import { Spinner } from 'reactstrap';
// hoc
import { withModal } from '../../HOC/Modal';
import { axiosInstance } from '../../HOC/Communication';
// constants
import { URLS } from '../../HOC/Communication/ApiUrls';
import { PARSING_KEYS, CAMPUS_METADATA, COUNTRY_CODE } from '../../Constants/AppConstants';

class FacilityCampusContainer extends React.Component {
    constructor(props) {
        super(props);

        this.init();

        this.state = {
            list: null,
        }
    }

    /**
     * init metadata
     */
    init = () => {
        this.metadata = CAMPUS_METADATA;
    }

    /**
     * get campus data after mount
     */
    componentDidMount() {
        this.axios = axiosInstance;
        this.getCampuses();
        this.props.handelBackButton();
    }

    /**
     * init campu object
     */
    initCampus = (element) => {
        const { CAMPUS } = PARSING_KEYS;
        const array = element[CAMPUS.WHATSAPP_NO].split(COUNTRY_CODE)
        if (array.length === 2) {
            element[CAMPUS.PARSED_WHATSAPP_NO] = array[1];
        }
        else {
            element[CAMPUS.PARSED_WHATSAPP_NO] = element[CAMPUS.WHATSAPP_NO];
            // throw error
        }
    }

    /**
     * edit campus
     * params list of data , id of campus
     */
    editCampus = (list, id) => {
        this.props.showModal(
            <Spinner />
        );
        const data = {};
        list.forEach(element => {
            data[element.key] = element.value;
            if (element.key === PARSING_KEYS.CAMPUS.PARSED_WHATSAPP_NO) {
                data[PARSING_KEYS.CAMPUS.WHATSAPP_NO] = COUNTRY_CODE + element.value;
            }
        })
        this.axios.put(URLS.CAMPUSES + id, {
            ...data,
        })
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    this.getCampuses();
                }
            });
    }

    /**
     * delete campus by id
     */
    deleteCampusById = (id) => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.delete(URLS.CAMPUSES + id)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    this.getCampuses();
                }
            })
    }

    /**
     * get campus by id
     * params element, callback
     */
    getCampusById = (data, callback) => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.get(URLS.CAMPUSES + data.id)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    this.initCampus(res.data.data);
                    callback(res.data.data);
                }
            })
    }

    /**
     * add campus
     * params campusData list, buildings list
     */
    addCampus = (campusDataList, bldgRequest) => {
        this.props.showModal(
            <Spinner />
        );
        const campusRequest = {};
        campusDataList.forEach(element => {
            campusRequest[element.key] = element.value;
            if (element.key === PARSING_KEYS.CAMPUS.PARSED_WHATSAPP_NO) {
                campusRequest[PARSING_KEYS.CAMPUS.WHATSAPP_NO] = COUNTRY_CODE + element.value;
            }
        })
        this.axios.post(URLS.CAMPUSES, {
            campusRequest,
            bldgRequest,
        })
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    this.getCampuses();
                }
            })
    }

    /**
     * get all campuses
     */
    getCampuses = () => {
        this.props.showModal(
            <Spinner />
        );

        this.axios.get(URLS.CAMPUSES)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    const list = res.data.data;
                    list.forEach(element => {
                        this.initCampus(element);
                    })
                    this.setState({ list });
                }
                else {
                    this.setState({ list: [] });
                }
            })
    }

    render() {
        return (
            <FacilityCampus
                list={this.state.list}
                metadata={this.metadata}
                showModal={this.props.showModal}
                closeModal={this.props.closeModal}
                addCampus={this.addCampus}
                editCampus={this.editCampus}
                onEditClick={this.getCampusById}
                getCampuses={this.getCampuses}
                deleteCampusById={this.deleteCampusById}
            />
        )
    }
}

export default withModal(FacilityCampusContainer);