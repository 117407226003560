import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faGlobe } from '@fortawesome/free-solid-svg-icons'; // Import the globe icon

const LongitudeIcon = ({ fillColor }) => {
  return (
    <div style={{ color: fillColor }}>
      <FontAwesomeIcon  size="1x" />
    </div>
  );
};

export default LongitudeIcon;
