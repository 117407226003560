import React from 'react';
import PropTypes from 'prop-types'
import {
    ModalHeader,
    ModalBody,
    Button,
} from 'reactstrap';
// hoc
import { withNamespaces } from 'react-i18next';
// css
import './PopupModal.scss';
// constants
import { LANGUAGE, DIRECTION } from '../../../Constants/AppConstants';

class PopupModal extends React.Component {

    static propTypes = {
        message: PropTypes.string.isRequired,
        title: PropTypes.string,
        isError: PropTypes.bool,
        onConfirmationClick: PropTypes.func,
        onCancelClick: PropTypes.func,
        onOkClick: PropTypes.func,
        body: PropTypes.object,
        bodyClasses: PropTypes.string,
        confirmButtonText: PropTypes.string, // Added prop for confirm button text
        cancelButtonText: PropTypes.string,
    };

    static defaultProps = {
        title: '',
        isError: false,
        onConfirmationClick: null,
        onCancelClick: null,
        onOkClick: null,
        body: null,
        bodyClasses: '',
        confirmButtonText: 'modal.buttons.confirm',
        cancelButtonText: 'modal.buttons.cancel'
    }

    render() {
        const direction = (this.props.i18n.language === LANGUAGE.AR) ? DIRECTION.RTL : DIRECTION.LTR;
        const title = (this.props.title) ? this.props.title : (this.props.isError) ? 'modal.error' : 'modal.success';

        return (
            <div className="popup-modal" style={{ direction: direction }}>
                <ModalHeader style={{ backgroundColor: this.props.color }}>
                    {this.props.i18n.t(title)}
                </ModalHeader>
                <ModalBody className={this.props.bodyClasses}>
                    {this.props.body}
                    {this.props.i18n.t(this.props.message)}
                    <div className="d-flex mt-3">
                        <div className="w-100" />
                        {
                            this.props.onCancelClick
                                ? (
                                    <Button
                                        outline
                                        onClick={this.props.onCancelClick}
                                    >
                                        {this.props.i18n.t(this.props.cancelButtonText)}
                                    </Button>
                                )
                                : null
                        }
                        {
                            this.props.onOkClick
                                ? (
                                    <Button
                                        className="ml-2"
                                        onClick={this.props.onOkClick}
                                    >
                                        {this.props.i18n.t('modal.buttons.ok')}
                                    </Button>
                                )
                                : null
                        }
                        {
                            this.props.onConfirmationClick
                                ? (
                                    <Button
                                        className="ml-2"
                                        onClick={this.props.onConfirmationClick}
                                    >
                                        {this.props.i18n.t(this.props.confirmButtonText)}
                                    </Button>
                                )
                                : null
                        }

                    </div>
                </ModalBody>
            </div>
        )
    }
}

export default withNamespaces()(PopupModal);